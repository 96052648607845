export const Genre = [
  "Afro-Fusion",
  "Alternative",
  "Afrobeat",
  "Rap",
  "Afro Pop",
  "Afrobeats",
  "Hip Hop",
  "Afro Soul",
  "R&B",
  "Dancehall",
  "Afrobeat, Hip Hop",
  "Pop",
  "R&B, Soul",
  "Afro Pop, Afrobeat",
  "Emo-, Trap",
  "Rap, Afro Pop",
  "Afro Cuban",
  "Hip Hop, Rap",
  "Alternative, R&B, Soul",
  "Afrobeats, Afro Pop",
  "Rap, Alternative",
  "Afrobeats, Pop",
  "Highlife",
  "Gospel, Highlife",
  "Folk",
  "Hip Hop, Pop",
  "Gospel",
  "Rap, Gospel",
  "Gospel, Rap",
  "Gospel, Afrobeats",
  "Pop, Afrobeats",
  "Afrobeats, Hip Hop",
  "Electronic",
  "Dance",
  "Hip Hop, Afrobeat",
  "Afro-Dancehall",
  "Soul, R&B",
  "Highlife, R&B",
  "Afro Pop, Rap",
  "Trap, Afro Pop",
  "Afro Pop, Afrobeats",
  "Afro Pop, R&B",
  "Soul, Jazz, Afro Soul",
  "Fuji",
  "Hip Hop, Afrobeats, Afro Pop",
  "Afro Pop, Afro Soul, Highlife",
  "Trap",
  "R&B, Afro Pop",
  "Rap, Hip Hop, Afro Pop",
  "Afro Soul, Soul, Alternative",
  "Hip Hop, Afro Pop",
  "Alternative, R&B",
  "Jazz",
  "Blues",
  "Soukous",
  "Afro Pop, Afro-Fusion",
  "Hip Hop, R&B",
  "Funk, Soul",
  "Psychedelic, Rock",
  "Funk",
  "Reggae",
  "Gospel, Hip Hop",
  "Jazz, Soul",
  "Afro Pop, Hip Hop",
  "R&B, Soul, Afro Pop",
  "Afrobeats, Pop, Funk, Afro Soul",
  "Afro Pop, Reggaeton",
  "Afro Soul, R&B",
  "Rap, Afro Pop, Hip Hop",
  "Soul, Alternative",
  "R&B, Gospel",
  "Folk, Country",
  "Hip Hop, Trap",
  "Electronic, Dance-pop",
  "Electronic, Pop",
  "Electronic, Hip Hop",
  "Boogie, Disco",
  "Hip Hop, Reggae",
  "Afrobeats, Gospel",
  "Pop, R&B",
  "Rap, Afrobeats",
  "Afro Pop, Alternative",
  "Soul",
  "Highlife, Afro Pop",
  "Gospel, R&B",
  "Rock, Alternative",
  "Alternative Folk",
  "Afro Pop, Afrobeat, Reggae",
  "Alternative, Rap",
  "Hip Hop, Pop, R&B, Gospel",
  "Funk, Pop",
  "Highlife, Gospel",
  "Afrobeat, Jazz",
  "Blues, Gospel",
  "Classical",
  "Rock, Funk",
  "Afrobeat, Electronic",
  "Funk, Afrobeat",
  "Reggae, Azonto",
  "Reggae, Soca",
  "Reggae, Pop",
  "Funk, Boogie",
  "Afro Pop, Highlife",
  "Christian",
  "Highlife, Hip Hop",
  "Afro Pop, Highlife, R&B",
  "Afro Pop, Hip Hop, R&B",
  "Juju",
  "Jazz, R&B, Soul",
  "Reggaeton",
  "Rock",
  "Funk, Soul, Afrobeat",
  "Afro Soul, Jazz",
  "Afrobeat, Highlife",
  "Jazz, Afro Soul",
  "Afrobeat, Jazz, Afro Soul",
  "Country, Jazz, Folk",
  "Soul, Jazz",
  "Jazz, Funk",
  "Funk, Gospel",
  "Electronic, Funk",
  "Gospel, Funk",
  "Rock, Reggae",
  "Waka",
  "Highlife, Jazz, Afrobeat",
  "Reggae, Highlife",
  "Electronic, Reggae",
  "Hip Hop, Funk",
  "Electronic, Boogie",
  "R&B, Hip Hop",
  "Pop, Gospel",
  "Pop, Soul, Funk",
  "Funk, Highlife",
  "Electronic, Disco",
  "Neo Soul, Gospel",
  "Jazz, Highlife",
  "Highlife, Soukous",
  "Soukous, Highlife",
  "Funk, Electronic, Soul",
  "Electronic, Hip Hop, Reggae",
  "Religious",
  "Reggae, Electronic",
  "Reggae, Gospel",
  "Afrobeat, Funk",
  "Latin",
  "Disco, Funk",
  "Hip Hop, Boogie",
  "Reggae, Roots Reggae",
  "Reggae, Boogie",
  "Highlife, Boogie",
  "Reggae, Folk",
  "Funk, Reggae",
  "Afro Soul, Funk, Pop, Reggae",
  "Highlife, Folk",
  "Reggae, Dancehall",
  "Apala",
  "Reggae, Funk",
  "Electronic, Gospel",
  "Juju, Highlife",
  "Folk, Gospel",
  "Pop, Boogie",
  "Boogie, Soul",
  "Dance-pop",
  "Soul, Funk",
  "Highlife, Pachanga",
  "Ogene",
  "Funk, Neo Soul",
  "Boogie, Funk",
  "Reggae, Funk, Soul",
  "Calypso, Boogie",
  "Choral, Gospel",
  "Gospel, Soul",
  "Boogie, Reggae",
  "Boogie",
  "Apola",
  "Boogie, Electronic, Funk",
  "Funk, Soul, Pop",
  "Hip Hop, Electronic",
  "Hip Hop, Electronic, Funk, Folk",
  "Folk, Highlife",
  "Highlife, Funk",
  "Disco",
  "Funk, Disco",
  "Juju, Funk",
  "Electronic, Jazz",
  "Highlife, Afrobeat",
  "Rock, Gospel",
  "latin, Afro Cuban",
  "Reggae, Funk, Disco",
  "Soul, Folk",
  "Apola, Juju",
  "Afrobeat, Boogie",
  "Gospel, Reggae",
  "Highlife, Juju",
  "Disco, Highlife",
  "Funk, Soukous",
  "Rock, Highlife",
  "Rock, Boogie",
  "Funk, Pop, Soul",
  "Soul, Pop, Funk",
  "Funk, Electronic",
  "Funk, Disco, Boogie",
  "Funk, Soul, Folk",
  "Folk, Soul",
  "Funk, synth-pop",
  "Funk, Juju",
  "Electronic, Funk, Soul",
  "Afro Soul, Funk, Pop",
  "Funk, Rock",
  "Pop Rock",
  "Folk, Juju",
  "Afro Soul, Funk",
  "Pop, Rock, Funk",
  "Reggae, Soul, Funk",
  "Rock, Funk, Soul",
  "Country",
  "Highlife, Disco",
  "Soundtrack",
  "Jazz, Rock",
  "Reggae, Afrobeat",
  "Blues, Funk",
  "Pop, Disco",
  "Funk, Soul, Rock",
  "Funk, Soul, Jazz",
  "Rock, Reggae, Funk",
  "Psychedelic, Reggae",
  "Rock, Disco",
  "Rock, Psychedelic",
  "Jazz, Reggae",
  "Afrobeat, Disco",
  "Sakara",
  "Funk, Psychedelic, Pop",
  "Funk, Psychedelic",
  "Psychedelic, Funk",
  "Rumba",
  "Blues, Folk",
  "latin, Highlife",
  "Rock, Reggae, Afrobeat",
  "Folk, Afrobeat",
  "Disco, Afrobeat",
  "Choral",
  "Afro Cuban, Soukous",
  "Reggae, Highlife, Gospel",
  "Jazz, Funk, Soul",
  "Soukous, Funk",
  "Afrobeat, Reggae",
  "Rap, Funk, Soul",
  "Funk, Rock, Soul",
  "Highlife, Merengue",
  "Jazz, Folk",
  "Psychedelic",
  "Rock, Afrobeat",
  "Highlife, Jazz",
  "Psychedelic, Afrobeat",
  "Highlife, Calypso",
  "Funk, Rock, Psychedelic",
  "Opera",
  "Highlife, Afro Cuban",
  "Disco, Reggae",
  "Juju, Folk",
  "Electronic, Soul",
  "Jazz, Gospel",
  "latin, Soukous",
].sort((a, b) => {
  let fa = a.toLowerCase(),
    fb = b.toLowerCase();
  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
});

export const ArtistList = [
  "Shallipopi",
  "Llona",
  "Kizz Daniel",
  "Young Jonn",
  "Rema",
  "Oyedele",
  "Kold AF",
  "Asake",
  "yinkah",
  "Blaqbonez",
  "Kolo",
  "NATIVE Sound System, Sholz",
  "Ykb",
  "Davido",
  "ODUMODUBLVCK",
  "Dej Loaf, Teni, Cheekychizzy",
  "Burna Boy",
  "Olamide",
  "Buju",
  "Tems",
  "Prettyboy D-O",
  "Mr Eazi",
  "Mayorkun",
  "Teni",
  "Basketmouth",
  "Zoro",
  "Psycho YP",
  "Reminisce",
  "Smada",
  "Joeboy",
  "Victony",
  "BOJ & Ajebutter22",
  "Morravey",
  "Cruel Santino",
  "Fireboy DML",
  "Lifesize Teddy",
  "Nonso Amadi",
  "Sarz",
  "Seyi Vibes",
  "Dele Sosimi Meets Medlar",
  "Raytheboffin",
  "Joe Nez",
  "Fave",
  "Dice Ailes",
  "Lil Frosh",
  "Adekunle Gold",
  "Jaywillz",
  "T.I BLAZE",
  "L.A.X.",
  "Terri",
  "Banky W",
  "Boj",
  "Skales",
  "Lyta",
  "Portable",
  "Yemi Alade",
  "Erigga, Jay Teazer",
  "Qdot",
  "Paragon Qtm",
  "Majeeed",
  "Boy Spyce",
  "Wurld",
  "Masterkraft, Phyno",
  "Praiz",
  "Brymo",
  "Falz",
  "Simi",
  "Khaid",
  "Naira Marley",
  "Nneka",
  "Asa",
  "Barry Jhay",
  "Sean Dampte",
  "Yung L",
  "Jess ETA",
  "Jeriq",
  "Jumabee",
  "Hotkid",
  "Mohbad",
  "AttiFaya",
  "Ladipoe",
  "SirBastien",
  "NATIVE Sound System, Arya Starr, Lojay",
  "Tomi Owo",
  "NATIVE Sound System",
  "Ninety",
  "NATIVE Sound System, Wani",
  "Lewisland",
  "Phlow",
  "Show Dem Camp",
  "Obong Jayar",
  "Sir Bastien",
  "Ayra Starr",
  "Chike, Simi",
  "Goodgirl LA",
  "Ruger",
  "M.I., Vector",
  "Loti",
  "CKay",
  "Shine Francis Odigie",
  "State Orji Moore And His Gili Brothers",
  "Evang Justus Okwudili",
  "Patty Obassey",
  "Obisco",
  "Cixqo",
  "Ibitayo Jeje",
  "Buzella",
  "Shimon Atunde",
  "I-Solo, JoDeep",
  "CalledOut Music",
  "Ademola Makinde",
  "Martinz Jr",
  "Austin Adigwe",
  "Debola Akran",
  "Henrisoul, Eben",
  "Damzy Khing",
  "Olufunmilola",
  "Dami Maverick",
  "JoDeep",
  "Remilekun",
  "Ami, Limoblaze",
  "Limoblaze",
  "Lil Kesh, Naira Marley",
  "Bella Shmurda",
  "Tystringz",
  "Xco The Monster",
  "Defayo",
  "Niniola, Oskido, GUILTYBEATZ",
  "DJ Neptune",
  "Skiibii",
  "Phyno",
  "Laycon",
  "Niniola",
  "Waje",
  "Waje, Masterkraft",
  "Johnny Drille",
  "Sarz, Obong Jayar",
  "Harrysong, Olamide, Fireboy DML",
  "Seyi Shay",
  "Dami Dante",
  "Fireboy DML, Ed Sheeran",
  "Ladipoe, Fireboy DML",
  "PrayzzZ",
  "Chike",
  "2Face Idibia",
  "Ajebutter 22",
  "Tiwa Savage",
  "Wande Coal",
  "The Cavemen",
  "Lady Donli",
  "DJ Cuppy",
  "Wizkid",
  "Omah Lay",
  "Oxlade",
  "AQ",
  "K1 The Ultimate",
  "Flavour",
  "Gabzy",
  "Jamal Swiss",
  "Paybac Iboro",
  "Remy Baggins",
  "Eri Ife",
  "Bilz",
  "Crayon",
  "Korede Bello",
  "Zarion Uti",
  "Famous Bobson",
  "Eeskay",
  "Loti, KD",
  "Yellw",
  "Alpha Ojini",
  "Jinmi Abduls, Oxlade, Joeboy",
  "Ycee",
  "Ikayh",
  "Tomi Thomas",
  "Gogowé",
  "Sute Iwar",
  "AYLØ",
  "BarelyAnyHook, D-Truce, BigFootInYourFace",
  "BarelyAnyHook",
  "Tekno",
  "Noir",
  "Ginny",
  "Jaido P",
  "9ice",
  "Patoranking",
  "Zamir",
  "Tomi Agape",
  "Uncle Victor Chukwu",
  "Hon. Sir Computer Onah and His Cosmic Vibration Worldwide",
  "Feezy Flex",
  "Pompay",
  "Isa Omojola",
  "Oni Julius",
  "Noah Aire",
  "Nickz",
  "Wizdom Fahad",
  "Hygrade",
  "Adelz",
  "Donkayyz",
  "Eghe Nimose",
  "SPG (Yung Alhaji)",
  "Avid The Lyrikal",
  "M-Josh",
  "Pat-E",
  "Skata Vibration",
  "Bantu",
  "Tabansi Studio Band",
  "Winning Jah",
  "Aramide",
  "ISCAC Band",
  "Eazy Bob Wizzy",
  "Sal Ly",
  "Teck-Zilla, Psalmurai",
  "GUILTYBEATZ, Joeboy",
  "Ajebutter 22, Boj, Falz",
  "Omawunmi",
  "DNA",
  "Chillz",
  "The Lost & Found",
  "Loti, Tim Lyre",
  "Deena Ade",
  "Jinmi Abduls, Oxlade",
  "Buju, Zlatan",
  "Tomi Thomas, GMK, Kida Kudz",
  "Wani",
  "Era",
  "Tay Iwar",
  "Ric Hassani",
  "Rose May Alaba",
  "Ada Ehi",
  "Flavour, Chidinma",
  "Emma Nyra",
  "Gigi Atlantis",
  "Martins Brothers",
  "General Bolivia Osigbemhe and His Great Santanas",
  "Odomore Osarenren And Ewaen Osetin Stars",
  "King Ojem",
  "Terry Tha Rapman",
  "Uwa Diva",
  "Dj Dennizz",
  "Jonz",
  "Frezzy",
  "Mode9",
  "Illbliss, Teck-Zilla",
  "Eric Kol",
  "Joquarter",
  "Mohdehn Lid",
  "Wyldo D'offizi",
  "Fasan Mirazy",
  "Olumireggae",
  "Jethro Lion",
  "Shaydee",
  "DJ Tunez",
  "Dibson & Essody",
  "ILLBLISS & TECK-ZILLA",
  "Modenine & Teck-Zilla",
  "MCskill ThaPreacha",
  "MCskill ThaPreacha & Stormatique",
  "MCskill ThaPreacha & Teck-Zilla",
  "Funbi",
  "Ajebutter 22, Boj",
  "DJ Prince",
  "M.I.",
  "Runtown",
  "Eri Ife, Remy Baggins",
  "Efe Oraka",
  "Psycho YP, Zilla Oaks, Ayüü, Apex Village",
  "Jinmi Abduls",
  "Cruel Santino, Nonso Amadi",
  "Femi Kuti",
  "Humblesmith",
  "Tiwa Savage, Duncan Mighty",
  "Leriq, Tomi Thomas",
  "Fasina, Adey",
  "Semah, Flavour",
  "Sij",
  "Pherowshuz",
  "Stephen Osita Osadebe Commander In Chief & His Nigeria Sound Makers International",
  "Paulson Kalu (Afrikhanah) & His Stars 25",
  "Prince Tommy Kiddy And His Expo '96 Oma Super SOund",
  "Danagog",
  "Adviser Isioma",
  "NEPATISM",
  "Sidney Leo",
  "Ignis Brothers",
  "Dwin, The Stoic",
  "Murphy Gingo",
  "Voices of The Cross (Brother Emmanuel & Brother Lazarus)",
  "Charlz Dogo",
  "Masterkraft",
  "Don Jazzy, D.J Big N",
  "Jitey Peters",
  "Ebisan",
  "Chidinma",
  "Ayo Jay, Davido, Olamide",
  "Magnificent Records",
  "Brother Okwey And The Master Servants",
  "Osayomore Joseph And The Ulele Power Sound",
  "Actor Nsiekumokwu",
  "Minz",
  "Akaycentric",
  "Ofo The Rock Company",
  "Maka & Phlow",
  "Maka",
  "eLDee",
  "Jaywon",
  "Bella",
  "Yinka Ayefele",
  "Iyanya",
  "Prom2pee",
  "3rty",
  "Ben Priest",
  "Kola-Bo",
  "Reekado Banks",
  "Ycee, Reekado Banks",
  "Ogak Jay Oke",
  "Da Emperor",
  "Da Emperor, Olamide",
  "Mic Iyke",
  "Lil Kesh",
  "Kiss Daniel",
  "Jide Obi",
  "Simi, Falz",
  "Boj, Simi",
  "DJ Jimmy Jatt, Davido",
  "Jesse Jagz",
  "Jahbless",
  "Naeto C",
  "Vector",
  "DJ Exclusive",
  "Davido, Meek Mill",
  "Illbliss, Mode9",
  "Oddy Jay",
  "Dr. Alaska Agho And His Uncle Dance Band Of Africa",
  "Oritse Femi",
  "Prince Tonny Kiddy",
  "K-Solo",
  "Chinedu Ogbaru",
  "Chimsnero Goldsmith",
  "Sir Dr Daddy Kris & His Sebem Sebem Sound of Africa",
  "Young Paperboyz",
  "Didi Blessing",
  "P-Square",
  "Timaya",
  "B-red, Phyno",
  "Shizzi, Davido",
  "Teck-Zilla",
  "Ifunanya Musical Group",
  "Sean Tizzle",
  "Prophet Afam Ogbuotobo (Sr) & His Ado-Nene Musical Group",
  "Onitsha Cultural Revival",
  "Agbaso",
  "Legendury Beatz",
  "Shola Allyson Obaniyi",
  "Destined Kids",
  "Dr. Sid",
  "Ice Prince",
  "Slim T",
  "Chuks Igba",
  "Agape Love Band Aba",
  "Ubulu International Band Of Nigeria",
  "Thompson Oranu",
  "Solo Hit",
  "Egedege Traditional Dance Group of Unubi",
  "Africa Tempo's Band",
  "Tony Grey",
  "Alhaji Chief Rasheed Ayinde And His Talazo Fuji Reformers",
  "KCee",
  "Agu Ubo Amodu Awkunanaw Group",
  "Otunba Wasiu Alabi Pasuma",
  "Slim Burna",
  "Destined Kids, Rejoice Iwueze",
  "Duncan Mighty",
  "Ruggedman",
  "Capital F.E.M.I",
  "Sunny Neji",
  "Beautiful Nubia and The Roots Renaissance Band",
  "Chief Ezigbo Akunwafor Obiligbo",
  "Ade Wesco And His Destiny Band",
  "Ori Okoroh",
  "Boogey",
  "Fecko The Emcee",
  "San Ceciel",
  "Fela Kuti and Ginger Baker",
  "African China",
  "Dare Art Alade",
  "Sojay",
  "Chief Commander Ebenezer Obey",
  "Onyenze",
  "Dr. Orlando Owoh & The Afrikan Kenneries Beat International",
  "Eric Enuma And His Inter Dance Band Of Nigeria",
  "Oliver De Coque & His Expo 76 Ogene Sound Super Of Africa",
  "Maz Mazua",
  "Adewale Ayuba",
  "Mo'Cheddah",
  "Jazzman Olofin",
  "Eedris Abdulkareem",
  "Lynxxx",
  "2Shotz",
  "King Sunny Ade",
  "Bro. Luke Ezeji & His Voice Of Kingdom",
  "Blackfacenaija",
  "Deebee",
  "Bracket",
  "Sina Ayinde Bakare",
  "General Pype",
  "BOUQUI",
  "Kel",
  "DaGrin",
  "GT The Guitarman",
  "Maleke",
  "Osapamwan Ohenhen",
  "Alhaji Labaeka Ibrahim",
  "Blo",
  "Peacocks Guitar Band",
  "Celestine Obiakor & His Entertainment Group",
  "Tony Allen",
  "Ben Okafor",
  "J. Martins",
  "Malam Spicey",
  "Djinee",
  "Pauline Njoku",
  "Charly Boy",
  "DJ Zeez",
  "Plantayshun Boiz",
  "Illbliss",
  "Klever Jay",
  "Rooftop MCs",
  "DJ Jimmy Jatt",
  "Lara George",
  "Tope Alabi",
  "Azadus",
  "Styl Plus",
  "Orlando Julius",
  "Toby Foyeh & Orchestra Africa",
  "Double-P",
  "Sunny Ade & His Green Spot Band",
  "The Mandators",
  "Trendy Boys",
  "Chioma Franklin Akpota",
  "Peter King",
  "Faze",
  "Paul Play",
  "Lagbaja",
  "Tony Allen, Fatai Rolling Dollar",
  "Mike Okri",
  "Various",
  "Tony Tetuila",
  "Fatai Rolling Dollar",
  "Fela Kuti",
  "Faze Alone",
  "Alhaji (Chief) Wasiu Ayinde Barrister",
  "Fuji Commander Alhaji Sikiru Ayinde Barrister & His Golden Fuji Exponent",
  "Prince Smart Williams & His Expo '96 Super Sound",
  "419 Squad",
  "Maintain",
  "Ayetoro",
  "Joan Adams",
  "Olufunmi",
  "Amala And His Afroculture Band",
  "Emeka Okeke",
  "Musiliu Haruna Ishola",
  "Obi Igwe And The Good Friends",
  "Prince Ogenwanne",
  "The Remedies",
  "Seyi Sodimu",
  "Elaiho Francis And His Festac Super stars Band Of Benin",
  "Tatalo Alamu",
  "Sonny Okosuns",
  "Abass Akande Obesere",
  "Daddy Showkey",
  "Fatayi Rolling Dollar, Tunde Nightingale",
  "King Pius L.I Fezena And His Alabeni Brother Band of Nigeria",
  "Queen Salawa Abeni",
  "Chief Honourable Vincent Ugabi Dance Band Of Agenebode",
  "Dr Sir Warrior, Oriental Brothers International Band",
  "Obiajulu Sound Power of Africa",
  "Ichie Godian Onuoha And His Agafe Music Makers",
  "Segun Adewale & His Superstars International",
  "Malizia",
  "Ras Kimono",
  "Pereama Freetown And His Youths Stars Band of Kpagiama Bendel State of Nigeria",
  "Go Slow & His Group Of Abraka, Delta State",
  "Mr One-Week",
  "Orlando Owoh",
  "Lekan Animashaun",
  "Gp. Fruit Juice",
  "De Lovers International Band Of Nigeria",
  "I.K. Dairo & His Blue Spot Band",
  "Chairman Allen Otobo And His New Era Music Band",
  "The Mystic Man Obiba Sly Collins",
  "Nino Diamund",
  "Alhaji Chief Kollington Ayinla & His Fuji '78 Organisation",
  "Harmony Kings International Band",
  "Sir Steady Arobby & His International Brothers Dance Band of Nigeria",
  "Ogie Super sound",
  "Zuki Nono & His Okwerekwesike Highlife Stars Band Of Africa",
  "Igiligi Sound Super Of Africa",
  "Rhythm King Chukzee Moses",
  "Ben King",
  "Healing Child",
  "Dele Taiwo",
  "Sir Victor Uwaifo And The Titbits",
  "Chris Mba",
  "Mr Wooden Legs",
  "Sentimabell Nkwelle",
  "Chief Stephen Osita Osadebe",
  "Jay-U Experience",
  "Sir Waziri Oshomah And The Traditional Sound Makers",
  "Darlington Obodo",
  "Intelligence Emeka Odogwu and His Izon Lovely Brothers' Band Tamigbe",
  "Abdullah Akinbode",
  "Prince Ndubisi Kalu & His Neighbors",
  "Freeman Chi",
  "Baba J. Chiky 'N' And His Wisdom Band",
  "Danny K And His Young Stars Of Nigeria",
  "King Robert Ebizimor",
  "Ugoras",
  "Bro. Joel The Shepherd",
  "Dayo Kujore",
  "The Hot Favorite Boyz",
  "Prince Umukoro",
  "Chuu Elobee",
  "Dread Francis Nikoro",
  "Kole-man Revolutionaire Orits Williki",
  "Mus 'D'",
  "Esse Agesse",
  "Timi Ekeremor Alias Headman Of Okumbiri",
  "Wailing Stone And The Heralds",
  "Twin Friends",
  "Lanrewaju Adepoju",
  "Elens And The Mustards",
  "Timi Osukoya Telemi Gospel Band",
  "Prince Brendy",
  "Orator Sinco",
  "Sir Shina Peters",
  "Harry Okere",
  "Feladey",
  "Vera Benson",
  "Dudi Adishi",
  "Jerry Okorodudu",
  "Barry Anthony",
  "Felix Lebarty",
  "Bassey Black",
  "International Show Promoter Memorial Band",
  "Abraham Young And His six Owigiri Stars of Nigeria",
  "Tosin Jedege",
  "Professor I.K Belemu And His Rivers Brothers Band",
  "Echo Toikumo And The Fisher Brothers",
  "The Golden Tones of Nigeria",
  "Bestman Doupere And His Coastal Pioneers Dance Band of Nigeria",
  "Boy Simon",
  "Eziomume Dance Band Of Nigeria",
  "Madam Aigbobesi Ikhenebomhe And Her Aimonoreye Group",
  "Fidelis Iwebem And His Nwaonye Dance Band",
  "Agbeotu Teyebo",
  "King Robert Ebizimor And His Izon Brothers Band Of Nigeria",
  "Prof Y.K Ajao & His Professional Band",
  "Nel Bannerman",
  "Pere Tony Ombolo",
  "Calixtus Ichaka Junior",
  "Benji Igbadumhe and His Okeke Sound International",
  "King Pele Ashogbon And His African Modernizer Beat",
  "Daniel Wilson",
  "Peter Globest",
  "Ugo Okwuosa",
  "Fuji Sandoka",
  "Chief Ogute Ottan And His Lucky Star",
  "Micho Ade & His african Stars Organisation",
  "Peter Eriri",
  "Nnenna G.I",
  "Ursy B",
  "Ayo-Manuel",
  "Stella Yama",
  "Rommy Anderson",
  "Love Idris Adisa (Tiro Tiro King)",
  "Alfa Abdu Lateef Oloto & His Muslim Carol Singers",
  "Dorcas Bentu",
  "Emman Pepsy",
  "Milly Turner Utuedor",
  "Lovelyn Edobor",
  "Kananga Band Of Nigeria",
  "Felix Finson",
  "Black Race",
  "Natty Andy",
  "Ras Chyke",
  "Albert Silver",
  "The New Waves",
  "Bolarin Dawodu",
  "Tyna Onwudiwe",
  "Segun Arinze",
  "Black Banana",
  "Chiddy Boy Lemmy Hands",
  "Rasman Maxwell Udoh",
  "Goddy Ezike And His Winners Of Africa",
  "Emma Ezeagu",
  "Madame Ivbiye",
  "Elvis Nzebude & His Metalic Sound",
  "Alpha Kuffa",
  "Peggy Curtis Imanah",
  "Youth Generation",
  "Ozzobia",
  "Counterforce",
  "Mc Skidd-O",
  "The Comrades",
  "Professor I.K Belemu",
  "Muddy Ibe And His Nkwa Brothers System",
  "Lanrewaju Adepoju And His Akewi Theatre",
  "Majek Fashek",
  "Onyeka Onwenu",
  "Chief Lecturer Easy Kabaka & His Progressive Fuji Organisation",
  "Blackky",
  "Alhaja Queen Salawa Abeni & Her Waka Modernizer",
  "Akara-Ugo Super Star Manuel Mavis",
  "Jericho Oshiorenua and His Heroes",
  "Ozoemena Nsugbe And His Oliokata Singing Party",
  "Bob Fred And His Ndokwa Super Dance Band",
  "Pericomo Okoye And His Traditional Dance Group",
  "Barrister S Smooth And His Young Shoppers Dance Band of Nigeria",
  "The Nitel Royal Band",
  "Asotele E. Endenapiagha And His Glorious Dance Band Of Tamogbene",
  "Master Fabomo Edoleyi And His Supersonic Sound Makers of Nigeria",
  "Golipat And His Dynamites Band of Ayakoromo",
  "General S.S Alaska Teibowe & His Niger Dekta Band of Odi",
  "White Eagles International",
  "Voice Of Victory",
  "Broda Martyns",
  "Professor Richard Ugaigbe And His Indegenous Highlife Band of Nigeria",
  "Ugbo And His Philosophers Of Urhonigbe",
  "Charlie Boogie",
  "The Root Vibrators",
  "Godday Okwedi And His Ngulueze International Band Of Africa",
  "President Rufus Ayafor & His Wenebuodeigha Dance Band Of Rivers State",
  "Easy Kabaka And His Progressive Fuji Organisation",
  "De Blenderz",
  "Kez Opara & Co",
  "The Talents Of Benin",
  "Fabulous Olu Fajẹmirokun And His Standard Stars International",
  "George Obiora Daniel",
  "Tamie Natis",
  "Tar Ukoh",
  "Timi Osukoya And The Muzikal Neighbors",
  "Betty Osagwona",
  "Alhaji (Prince) Suajudeen Alamu Akere (Shaura)",
  "Hycentto Junior",
  "D' Rovans Band",
  "Okeluwa Brothers International Band Of Africa",
  "Etiene T. Boy",
  "Adu Deme",
  "Udo Abianga And The Gazers Band",
  "T'na & Prophet",
  "Edionzee Dada",
  "Sonnie Sly",
  "Peterside Ottong",
  "Enjel Stevvie",
  "Emmy Cetus",
  "Jyk Ernest",
  "African Boyz",
  "Prince Weekender",
  "The Sojourners",
  "Isaac Okafor",
  "U.N Uchenna",
  "Prince Chyke Jonathan Snr. And The Exhort Fraternity",
  "Desmond",
  "Wonderful Dayo Kujore And His Everly Brothers Band",
  "Righteous Man",
  "Allan Ramson And The Boles Of Obiaruku",
  "Rick Asikpo",
  "Ai-Kay",
  "Dr O",
  "Irie Man Stevie O",
  "Great Yomi Peters",
  "Dee Lash",
  "Prince B",
  "Segun Awe & The African Stars Connection",
  "Pretty Busy Boys",
  "Naty 'B' & The Root Rastas",
  "Rootsman And His Roots Beat",
  "The Marshall",
  "Terry Mackson",
  "Rita EF",
  "Endee Ikeji",
  "Ras Offorman",
  "Morgan & His Ayakaya Band",
  "Adeyinka Ayinla & The Bata Fuji Reformer Stars",
  "City Holy Gang",
  "Chris Hanen",
  "Best Agoha",
  "Victor Oris",
  "Vivian Oruma",
  "Alan G. George",
  "The Ofilys",
  "Evi Edna Ogholi",
  "Francis Goldman",
  "Jimmy Akama",
  "Christiana Essien",
  "Stella Monye",
  "Ben More",
  "Funmi Adams",
  "Bright Chimezie",
  "Original Brothers International of Imo State",
  "Great Ojo And His Family Noble Band",
  "Professor I.K Belemu And His Owigiri Exponents",
  "Eberiye S.S And His Riverine Philosophers Band",
  "Idowu Animasawun & His Lisabi Brothers International",
  "Chi-Chi Onwuama",
  "Fela Anikulapo Kuti & Egypt 80",
  "A.S. Eseduwo & His Top Stars Band of Nigeria",
  "Sayless Jah",
  "King Pele Ashogbon",
  "Isaac Rogana Ottah & His Black Heroes",
  "Joseph Eyanu & His Group of Agbara-Oto",
  "Queen Azaka Ebologu Abusu Mma",
  "Orlu Brothers Band",
  "Aghasagbon And His Elegbe Super Sound",
  "Sir Patrick Idahosa & His African Sound Makers",
  "Johnson O. Adjan And His Group",
  "Jah Blessing",
  "Dupe Solana",
  "Staness Garrison And The Latter Rain",
  "Tony Man",
  "Udoh Abiana",
  "The Upper Justice",
  "Prince Nwafili Ezekude",
  "Man Olivet",
  "Benson Amojuku And His Udje Group Of Oghara",
  "Chris Goziem",
  "Okpan Arihibo And His Group Of Ewherhe Agbarho",
  "Anyi-Chukwu Nwabueze And His Oshimili Stars Band Of Nigeria",
  "Unik Bloke",
  "Amos McRoy Jegg",
  "Bayo Adegboyega And The Atayero Voices",
  "Siakar Steve 'O' Ragamuffin 'Boss'",
  "Chief Prince Morocco Maduka",
  "O.K Jazz A And His Royal Beats Of Africa",
  "Bob Sally King",
  "Exempts E & The Trinity",
  "Dan Ian",
  "Precious Alake",
  "Edmund Spice",
  "Manik Leo",
  "Cycely",
  "Maureen Ejezie",
  "Kenny Em",
  "Souleman",
  "Emma Dorgu",
  "Waleman",
  "Loraine Okotie",
  "Emma Ogosi",
  "Oby Nwankwo",
  "Dele Abiodun and His Top Hitters Band",
  "Monday O. John",
  "Funmi Aragbaiye",
  "Don Bruce",
  "Collins Oke Elaiho",
  "Orits Wiliki",
  "Veno Marioghae",
  "Alhaji Professor Kollington Ayinla & His Fuji ’78 Organisation",
  "Sir Shina Peters & His International Stars",
  "Austine Oriaku & The Truth Spreaders",
  "Fati B",
  "Kabaka International Guitar Band",
  "Popular Cooper And His All Beats Band of Nigeria",
  "Fela Anikulapo Kuti & Afrika 70",
  "Crown No 1 And His Nigie Rockets",
  "Eveready Dance Band of Ozoro",
  "Power Asiafa & His Moon Shine Dance Band",
  "Fine S. Diaware And His Zion Brothers Band Of Nigeria",
  "Goddy Ezike And The Black Brothers",
  "King Black Man Oluma",
  "Karis",
  "J.O Omoruyi And His Black Beat",
  "Show Promoter",
  "Prince Chuddy Ojukwu",
  "Aya Sarah",
  "Vicky 'Manze Onuba",
  "Gladys Onoziakpo",
  "The Blackroots",
  "Okey Obi",
  "Freeworld",
  "Dready Samiko",
  "Goddy K. Obi",
  "Afam Okeke",
  "Martin'Ze De Unicoco And His Ijere Sound Maker Of Africa",
  "Jah Wisdom",
  "Mmadu Osa International Band",
  "Famous Furious Two",
  "Lagos Swing College Band",
  "Musical Breed",
  "King Sunny Ade & His African Beats",
  "Agu Risky",
  "Jan Blast",
  "Uche Ibeto",
  "Benji Igbadumhe & His Okeke Sound International",
  "Dizzy K",
  "Nelly Uchendu",
  "Roses",
  "Pascal Okafor, The Good Brothers Band",
  "Eugene Mbaebie",
  "Sir Waziri Oshomah & His Family Traditional Sound Makers",
  "Senator Oby And Anaedo Sound Melody",
  "The Unity Singers",
  "Odogu International Dance Band",
  "Batile Alake And Her Waka Group",
  "Joseph Edoh & His Group Of Orhakpor",
  "Tony Makafor",
  "Sola Rotimi",
  "Benard Erriom & His Bekpor Irruan Beat",
  "Cardinal Donald Udoh & His Mighty Rockers Band",
  "Great B.K.O Kaizer And His Professional Band Of Rivers State",
  "Alhaji Chief Wasiu Ayinde Barrister & His Talazo Fuji Commanders",
  "Chief Omokomoko and His Group",
  "Anthony Cockson And His Oyadonghan Dance Band Of Tarakiri, rivers State",
  "Fagbola, The Turtles",
  "Nancy",
  "Sir Ojoto And His Nighbros Of Tamigbe",
  "The Gospel Gang's",
  "Tobi Bad Messenger",
  "Ivbiye Dance Band Of Ugo",
  "Monkey No Fine Dance Band Of Nigeria",
  "Ike Izuegbu",
  "Gabriel Olili Nwalieji & The Rosy Melody Dance Band",
  "Awada Kerikeri Organisation",
  "Phea Hookson",
  "Fettered Fury",
  "The Harbingers",
  "Bayo Adeboyega",
  "Demos Deniran & The Prince Of Peace Disciples",
  "Georgy-Gold Owoghiri",
  "Zaire All Stars",
  "William And The Young Five",
  "Emman Nmankor",
  "Boss Ments Ezekafor",
  "Ikuku The Midnight Band",
  "Jolly Ebonwu",
  "Dukesie Uzere",
  "Iyabo",
  "Mike Obi",
  "Dombraye Aghama & The Stars Of Benin",
  "Funmi Davies",
  "The Women's Group Of Akahaba Age Grade Of Abiriba",
  "Fred Fisher",
  "Mrs. Biddy Uchendu-Onwuka",
  "Ibealaoke Chukwukeziri & His 'Anaedeonu'",
  "Princess Bumy Olajubu",
  "Oby Onyioha",
  "Eunice Mokus Arimoku",
  "T Bones Family",
  "TLZ",
  "Zik Emmy",
  "Imo Brothers International Band",
  "Dr Julidozies Okafor",
  "The Wazobias Band of Africa",
  "Olabisi Abegbo And Her Supreme Waka Organization",
  "Mike Ejeagha & His Premier Dance Band",
  "Haruna Ishola & His Apala Group",
  "Gospel Youth",
  "Professional Seagulls Dance Band Port Harcourt",
  "Akwunaechenyi Nkwelle Awkuzu",
  "Alhaji Ayinla Omowura Memorial Band",
  "Dread Evans",
  "The Information Society",
  "Chief Benbella With His Amara System",
  "Chief Dan Sally Young",
  "Basco Bassey",
  "Dr. C. K. Morgan And His Black Angel",
  "Eyim International Band",
  "The Doves",
  "Efieduomo Igiemeh And His Tremendous Band Of Etsakor",
  "Skido Ozidi And His Rivers International Dance Band Of Ekeremor",
  "Mary Kanu & Oganihu Arochukwu Cultural Group",
  "Super Godwin Enoghase",
  "Morocco Maduka & His Group",
  "Zanga Zanga",
  "Francis Uba",
  "Gee Constant",
  "Dickson Njoku",
  "Rasta Wilcox Mbamara",
  "Bob Hyman",
  "U.K. Ndukwe",
  "U. D Bucknor",
  "Sunny Cokey",
  "Jona Igbinigie",
  "Godwin Immortal Egwuatu",
  "Douglas Ederi Chukwueke & His Olariche International Guitar Band",
  "Rev. Robinson Amadikwa",
  "Kamga Cedric Brown",
  "Mr Fine Boy",
  "Mercy Kelvin",
  "Etioma Dance Band Of Africa",
  "Mazi Herbert Ndiangwa And Arochukwu Traditional Dance",
  "Wiser Generations",
  "Bob Actor",
  "Christian Negus And The Biafran Wailers",
  "Too Low For Zero",
  "Ikeduru Brothers Band",
  "Sir Tony Lord Nwa Agulu & The Highlife Lovers",
  "Chimon",
  "McDonald Enwemeka",
  "Tera Kota",
  "Prince Nico Mbarga",
  "Leonard Okpala The Peace Maker And His Ichie Super Dance Band",
  "Bayo Ade And His Miliki Band Of Benin-City",
  "Funny Party",
  "Ken Nwofor",
  "General Ehi Duncan And The Africa Army Express",
  "Felix Uwaerue's Ika International Express",
  "Rowland Onwuama",
  "Blackman Akeeb Kareem",
  "Rosaline Ogunro",
  "Kingsley Bucknor",
  "Ikenga Super Stars Of Africa",
  "Dur-Dur Band",
  "Dr Victor Olaiya",
  "Marlene",
  "Alhaja Hadassah Waziri & Her Velvet Voice",
  "Sweat",
  "Segun Alile",
  "The Semi-Colon",
  "Dr Adolf Ahanotu",
  "Buddy Otuole and His Udoka Brothers International",
  "Good Women Choir of C.A.C Ibadan",
  "Popular Osi and His Lucky Band of Odi",
  "C.M.S Grammar School Of Lagos",
  "Ova Theatre Group",
  "Bro Bannerman Embiowei",
  "Akeem Ayinla Omowura",
  "Mein Owei Brothers Band",
  "Prince Oju Ifeanyi Super Band of Nigeria",
  "Isiaka Iyanda Sawaba",
  "Otu Ifeoyelolu Nwamadu Cultural Organisation Of Ibusa (Lagos)",
  "Sikiru Ishola Oloyede and His Apala Group",
  "General Organisation",
  "Okey Ihedioha, Orlu Brothers Band",
  "Obuaku Brothers International Band Of Nigeria",
  "The Rolling Stars International Band",
  "Winston Limited",
  "Iyke Emman",
  "Sir C.K Morrison",
  "Peanock & His Dance Band Of Rivers State",
  "Sunny Omoaregba And His Traditional Band Of Iuleha",
  "P.T. Foo And His Jolly Band Of Nigeria",
  "Ikura Cultural Musical Group Of Ihioma",
  "Bola & Her Spiritual Singers 'Ibadan'",
  "Dennis A. Keku And His Rainbow Brothers Band Of Isampou Rivers State",
  "Brother Isaac Okoba",
  "Francis Akintade & His Great Brothers Band",
  "Ruby Jah",
  "Fidel E",
  "Monday Edo Igbinidu",
  "Chief Isekhure",
  "Bibi Den's Tshibayi",
  "Queen Azaka",
  "Donald Bossah Enemoh",
  "Tony Achilike",
  "Otu Ife Onye Wepu Anya Dancing Group Isele-Azagba (Lagos)",
  "Chimex G Udensity And His Afrikan Band Organisation '86",
  "Late Adeolu And His Decem Stars",
  "Bob Dazzy",
  "Yinka Abayomi",
  "King Kennytone",
  "Randy Isdore",
  "Cy Akwor",
  "Apola King Idowu Animasawun",
  "Mannix Okonkwo",
  "Akwunechenyi Dancing Group",
  "I. K. Mudodo Osagie And The Kokonute",
  "Federal Emmison Papa & His Stich In Time Band Of Nigeria",
  "Frank International & His Band Group",
  "Donaldson Maduh Jr.",
  "O.U. Willson",
  "Jimi Solanke",
  "Sam 'Ooh' Onyemeh",
  "Mandy Brown",
  "Steve Black",
  "Princess Futi",
  "Dr. Olololo And Mbaise Brothers Band",
  "Mr Goodluck",
  "Larry Ubozoh",
  "Hotline",
  "I.K. Ejimadu",
  "Judy Nackix",
  "Angela Starr",
  "William Onyeabor",
  "Mike Umoh",
  "Dizzy K. Falola",
  "George Maduka",
  "Abuba Ugo Onyema & His Traditional Music Singers",
  "Rap Master Lexy Mella",
  "Tony Okoroji",
  "Ali Chukwuma And His Peacemakers Dance Band",
  "Kuburat Alaragbo",
  "St. Augustine and His Rovers Dance Band",
  "Original Wings International, Arinze Okpala",
  "Alhaji (Chief) Sikuru Ayinde Barrister And His Supreme Fuji Vibrations",
  "Melvin Ukachi",
  "Alhaji Chief Micky Adisa",
  "Alhaji Chief Toibu Alamu",
  "Chief Iney Ugussey",
  "Alhaji Mamman Shata",
  "Ayewa International Gospel Singers",
  "Boroism International Band of Nigeria",
  "Three Brothers Dance Band",
  "The Water Babies International",
  "J.C Obidike",
  "Jerry Deemua",
  "Nwa Ihe Ana Ebo Agu and His Men",
  "Success International Dance Band",
  "Prince Mochico Bay And His Melody Makers",
  "Lord Omiye",
  "The United Brothers Band Of Toru Orua Salga. Rivers State",
  "Unity Star Dance Band, Afagha Offiong",
  "Ngozika Brothers Dance Band",
  "Ugochukwu Anyaso And His Musical Group",
  "Pepsis Sound Fiesta",
  "Sister Mrs Christina Ezeh",
  "Y. S Sule And His Fellow Stars Band",
  "Rennie Cotton Heart",
  "Jeo Simon & The Golden Bell",
  "Chieze International Band Of Nigeria",
  "Ulalia Wosar",
  "Ezekiel Nwankwo And His Musical Party",
  "Sally Young And His Ufuoma Dance Band",
  "Alhaji Rasheed Adio & His Funky Fuji Modernizers",
  "Voice Of Ubulu Boys",
  "Dickson O",
  "Tunde Ogunsakin",
  "Eastern Brothers Band Of Africa International",
  "Aforo Dance Band Of Ojobo Town Bendel State",
  "Grabid",
  "Sunny Pedido",
  "The Original Egwu Obi Cultural Dance Of Nigeria-Umuoli Umuoji",
  "Ahuja Bello And His Golden Band",
  "K.C Obi",
  "Amos Makanjuola And His Group",
  "Unity Dance Band Of Abavo Bendel State Nigeria",
  "Prince Kene Nweze",
  "Obuluzor Boys Band",
  "Johnson Hart",
  "Vado",
  "Hajiya Fatima Lolo& Her Nupe Group",
  "Ifeanyi Agwuedo And His Njikoka Singing Group Of Umuolum",
  "Egwu Otu Odogwu Oraifite Lagos",
  "Osuma Stars",
  "The Meditations",
  "Calabar Choral Party",
  "Rise Africa",
  "Bessman Ade",
  "Third Degree Family",
  "The Eternal Choral Group Of Cherubim And Seraphim",
  "Shola Johnson & His Selected Superstar Singers",
  "Chief Twins Seven Seven & The Black Gold International",
  "Eji Oyewole",
  "Alhaji Tajudeen Alade & His Supreme Fuji Vibrators",
  "Chris Okon",
  "Die De Matter And His Ojemba Brothers International Band",
  "Amechi Melody Singers Awkunanaw Enugu",
  "Fine Boy Meka Egwu",
  "Angus Rich",
  "Bouasi International",
  "Bob Livingstar",
  "Joe Briel",
  "Tayo Jimba & His Black Shadows",
  "Blessing Dokubo Russell",
  "Nigeria Police Band",
  "Voice Of The Glad Tiding Singers",
  "Jack Mowe",
  "Gentleman Mike Ejeagha And His Trio",
  "Jerry Ella",
  "Jimmy Johnson",
  "Rob Amadikwa",
  "Demola Adepoju",
  "Yusef Lateef",
  "Martha Ulaeto",
  "Osayomore Joseph & The Ulele Power Sound",
  "Mona Finnih",
  "Carol Bridi",
  "Madam Hassanah Waziri & Her Velvet Voice",
  "Eppi Fanio",
  "Lucky Brothers Organisation",
  "Luna Professionals",
  "Uncle Victor Chukwu, The Black Irokos",
  "Japhet Igwe",
  "Kuburat And Her Waka Performers",
  "Fatai Alao",
  "Professor Johnson Adjan",
  "Xtasy",
  "Alhaji Dauda Epo Akara And His Awurabe Experts",
  "Dr Victor Olaiya & All Stars",
  "Remi Agim",
  "Willy Rhanis And The Levites",
  "Omo Jesu Christian Band Of CAC Agege",
  "Chidi Ogbonna And His Golden Stars Band",
  "King JosyFriday & His African Royal Singers (Nig)",
  "Director Ade Fadeyi And His Kalesanwa Star Band",
  "Emperor Pick Peters & His Seidormatic System",
  "Kate Kelly Eze",
  "Juju Sound Millionaires",
  "The Zioneers",
  "Enemuwe Brothers International Band Of Nigeria",
  "Udokanma Women Dancing Group",
  "Tony Allen, Afrobeat 2000",
  "Prince Eric Nsangou & The Meya Meya Jazz",
  "Yemi Elebuibon & His Ancient Philosophy International",
  "Collins Oke Elaiho & His Odoligie Nobles Dance Band of Nigeria",
  "Alhaji Fatayi Olowonyo And His Reformed Apala Band",
  "Sir Lemmy And His Anioma Brothers International Band",
  "Bebes Aboubakar",
  "Princess Diana And Her Izon Sisters Band Of Rivers",
  "Shina Williams & His Africa Percussionists",
  "Prince Bolingo Akaba",
  "Joakin And His Royal Guitar Band",
  "Osaije Dance Band Of Iuleha",
  "The Shining Stars Of Iuleha",
  "Ihievbe Ebo Dandies",
  "Obakhavbaye And His Dance Band Iguobazuwa",
  "Muchacha Imuzor And His Group",
  "De Kannanites",
  "The Solid Rock",
  "Chief Jimoh Aliu And His Cultural Group",
  "Sons Of Spirit",
  "Goddy Oku",
  "Goddy Na Achinkwa",
  "Emma IK. Okpala",
  "Senior Dancer Sanubi",
  "Traditional Brother's Omenalaizm",
  "Raphael Ibikunle Ajimuda And His Jolly Brothers Band",
  "Egbe Belu Ugo Belu Une Nwo",
  "The Original Egedege Of Africa",
  "Sambros International Dance Band Of Nigeria",
  "Frank Fyne",
  "Sunshine Sisters Of Africa",
  "Sir Victor Uwaifo",
  "Esombi Jones",
  "Meli Mathias",
  "Souradjou Alabi And His New Star International Apala Group",
  "Etubom Rex Williams & His Nigerian Artistes",
  "Mrs Biddy Uchendu",
  "International Sound City Band Of Nigeria",
  "Spinner Odiokpe",
  "Wazbar Super Sound International Band",
  "Chike Iloka",
  "Obi Wuru Otu Dance Group Of Ihiagwa-Owerri Imo State",
  "Alhaji Chief Maitama Sule His Fuji Pioneers",
  "King Oloje International Sound Super Band Of Afikpo Imo State",
  "Tony Imadojemu",
  "Sammy Comfort Ogwo",
  "Queen Salawa Abeni & Her African Waka Modernisers",
  "Handsome Wale Abiodun & The Black Beetles Of Africa",
  "Fidelis Ubah",
  "Kalu Omoji",
  "Apola King Idowu Animasawun & His Lisabi Brothers International",
  "Gboyega Adelaja",
  "The Apostles",
  "Ike Peters",
  "Tunji Oyelana and The Benders",
  "Wole Soyinka, Tunji Oyelana and The Benders",
  "Sir Juju Udjabor Group Of Aladja",
  "Gentleman Ikechukwu And His Rhythm Masters of Nigeria",
  "The Royal Banner",
  "Miki Jaga Dance Band of Nigeria",
  "Sir Waziri Oshomah And His Family United Band Of Afenmai Nigeria",
  "Lord Adusa And The Youngsters Band Of Rivers State",
  "Allwell Nwosa And His Ebologwu Band",
  "General Nathan O. Udisi & His Woyengi Emi Dance Band Of Odi",
  "Obeagu Agbani",
  "Ligali Mukaiba And His Apala Group",
  "The Revival Singers",
  "Mike Okeke",
  "D.A Ogbebor & His Super Sound Of Nigeria",
  "Prince Omo Lawal-Osula And His Royal Music Messengers Of Nigeria",
  "Yvonne Maha",
  "Sunny Risky",
  "Ucheka and The Sound City Brothers",
  "African System Orchestra",
  "Yotomi Afolabo & Her Ifelodunu Group",
  "Smart Lee",
  "Amadikwa Chikwere Jr.",
  "Yommy Akins And His New Melody Sounders",
  "Weppa Wanno Sound",
  "Ogoo-Madu And His Orchestra",
  "Solomon Imaghodor And His Dance Band Of Nigeria",
  "Mike Agoha And His Echoes Of The Days",
  "Osabohien Cultural Group And His Ezomo Group",
  "Oluwole & Great International Brothers Band",
  "Chika Ezenwa",
  "Okay Kalu",
  "Stanley Edet Jr",
  "Peter Effiom And His Rabalac Messengers",
  "Akanni Animashaun And His Apala Group",
  "Moses Adeolu Akinsanya",
  "John Ewelukwa",
  "Kelly Udeji & His Okpilla Group",
  "Grant Obiys",
  "Comfort Omoge And Her Asiko Ikale Group",
  "Patty Juana",
  "Ifeanyi Akwete Uwayah And His Bendelites International Band",
  "Otu Onwu Egbu Nwukete Cultural Group Of Ibusa",
  "CCF Band",
  "Papa Doe",
  "Pele Asampete",
  "Prince Emmanuel And His All Stars Band",
  "Rev. Mrs Florence Oluwasanmi",
  "Livy Ekemezie",
  "Joe Ejiogu",
  "Ojile & His Isoko Sound Makers",
  "Kay-Kay Kalu",
  "Obiwuruotu Dance Group Of Nigeria",
  "Odion Iruoje",
  "Wazba All Stars Band Of Nigeria",
  "Undisputed Edo Reggae Express",
  "Igwete International Band",
  "Prince Henry De Great",
  "Emp. J.C.C. Aidiyi Obaseki And His Colossal City Stars",
  "Rock Of Ages",
  "Emeka Oxbon",
  "Eagle Brothers Band",
  "Dennis Akwah And The Glitter Destiny",
  "Bongos Ikwue & The Groovies",
  "Jeje",
  "Burnis",
  "Harry Mosco",
  "Onyeka Onwenu, Mohammed Arahi",
  "Doris Ebong",
  "Bachirou Ayinla Omooyinkanola & His Apala Group",
  "T Bones & The Coritz Family",
  "KG Brothers",
  "United Brothers International Band",
  "Uncle Victor Chukwu, Anambra Brothers International Band",
  "Obidike International Band",
  "Pete Baja",
  "Popular Cooper",
  "The Visitors",
  "Precious Alake And His African Mirrows",
  "Zion Sisters",
  "The Betters",
  "Professor Richard Ugiagba & His Afro Ministers Band",
  "Charism Band",
  "Ken Moore And The Jah Lovers",
  "Okwuamara Dancing Group",
  "Omon Christo",
  "The Otarus International Brothers",
  "Fatayi Ayilara And His Apala Group",
  "Notoyor International Dance Band Of Egbokor",
  "The Good Samaritans",
  "Cross River Radio Band",
  "Okwomma Club Akweze",
  "Racheal Jerry I. And Her Golden Voice '82",
  "Akashiada International Band",
  "Young Aladesuyi & His Bright Star Organization",
  "Dikumo & His Royal Delta Band of Odi Rivers State",
  "Azagba -Ubeni Brothers Band",
  "Robert And His Ijaw Brothers",
  "Seveho Gandje Bebe-Non And His Egun Dance Band",
  "Evangelist Gima Gosa And His Gospel Band",
  "Ayinla Omoruwa & His Apala Group",
  "Hon. Fasco Dagama And His Super Reformers Band",
  "Iuleha Dance Band International Of Africa",
  "Alhaji Chief Raji Owonikoko & His Bully Sound International",
  "Garden City Int. Band Of Port Harcourt",
  "Soki Ohale",
  "Bob Fred And His Ukwuani Dance Band",
  "Kris Okotie",
  "Etubom Rex Williams And His Nato Funky Stars",
  "Ignis Philip Okwu",
  "Amphibious Band International",
  "Inimeto & His Parrotwood Sound Makers Band Of Africa",
  "Rich Rich International Band Of Nigeria",
  "Band Of Bigard",
  "Isi Kezie Unlimited",
  "Sam Plazar Nebuwa",
  "Continental Bros Band Of Owerri",
  "Sanya Abiodun",
  "Mexwell Opara Funk",
  "Asi Balewa And His Traditional Sound Hit",
  "Paul Ede & His Edorisiagbon Musical Union",
  "Amunataba Dance Band",
  "Actor Alile And His Ugie '75",
  "Sir Paulingo Ufumwen And His Happy Sound",
  "Professor Adomagbo Irene And His Weppa - Wano Dance Band Of Agenebode Etsakor Local Govt. Bendel State",
  "Peter Effiom & His Rabalac Messengers",
  "Bola",
  "Emma Baloka",
  "Enyimbe Cultural Dance, Lagos",
  "Sir Maliki Showman & The Golden 9 Of Africa",
  "Professor Adomagbo Irene And His Native Blue",
  "Ihube Guitar Band Led By Bishop Vincent Onyenali",
  "Chris Orajekwe",
  "The Mighty Brothers Band Of Nigeria",
  "Anafi Alao And His Apala Group",
  "Anayo Ilodi",
  "Baba J.B Obabueki",
  "Uko Akpan Cultural Group",
  "New Voices By Chikodili",
  "Ichie East International",
  "Great Abaraka International",
  "Robert Emoikhare And His Solo Hits",
  "States Brothers International",
  "Anioma Brothers International The Band of Nigeria",
  "Jah Rasta",
  "Abaraka '77 Original Band Of Nigeria",
  "Super Sound Mixers Of Africa",
  "Johnson A. Elebe And The R & J Dance Band",
  "Remi Olabamiji",
  "Jonathan Onwuama",
  "Ovio Okabo N'Ogbona Dance Band",
  "Scatter Rocks Band",
  "Emperor Wale Olateju And His Sabada Sounders International",
  "Okwute International Band",
  "Igwilo Igwilo And The Rainbow",
  "Tony Ukate",
  "Theophilus Iwalokun",
  "Alikaman & Hia Afa Rock Highlife Band",
  "Brother Ephraim",
  "Ogenromwon Union Dance Band",
  "Professor Angus Makeva & His Odukwental Super System",
  "Iyke Peters",
  "Bassey Archibong",
  "Inyang Henshaw & His Top Ten Aces",
  "Obianwu Family",
  "Odili Igabor's Ogwezi (Afro-Funk Group)",
  "Jerry Boifraind",
  'Eric "Show Boy" Akaeze & His Azagas',
  "Monica Isaac",
  "T-Tek",
  "Akin Nathan's Ijama",
  "Sir Shadow & The Eyimba Superstars Band Of Aba",
  "Isadico Dance Band Of Nigeria",
  'Gbenga Adeboye Alias: Alhaji Pastor Oluwo "Funwontan"',
  "Leonard Okpala De Peace Maker And His Ichie Super Dance Band Of Nigeria",
  "Sir Friday Maduka And His Ekwe Super Sound International",
  "Frank Izuora",
  "Benis Cletin",
  "Agbefẹ & His Social Band",
  "Lloyd Baker",
  "Kio Amachree",
  "Queen Orete and Her Ijewele Dance Band",
  "Oteghe Union",
  "Raphael Amarabem And His Revived Peacocks",
  "Pascal Okafor",
  "Original Wings International",
  "S.K. Karibo And His Ijaw Delta Band of Dancers",
  "Uncle Toye Ajagun And His Olumo",
  "Chief I. I Ayeni And His Group",
  "S. Aka And His Sakara Group",
  "Prince Tola And His Standard Beats",
  "Pat Okonye And Asamala Brothers",
  "Prince M. Wusul And His International Mavelus Egun System",
  "Ozalla Asologun Dance Group",
  "The Stormmers",
  "Ogada Super Sound of Africa",
  "Willy Rhanis And The Heavy Brothers",
  "Eddy Okonta & His Aces Seventeen",
  "The Evangelical Crusaders",
  "Rich International Jet Band",
  "Femi Akinyemi's Juju Lucky Stars",
  "City Rangers International Brothers Band",
  "Birifou And His Amaebimo Group",
  "Arab-Len And His Super Morning Stars",
  "Anco Momodu & His Afenmai Dance Band",
  "Theadora Ifudu",
  "Bright Osadebe And His Jolly Sound Makers",
  "Nwanne Ejimogu And The Omobizu Dancing Party of Ibaa (Kelga)",
  "Eddy Ojuyenum & His Man De Love Band",
  "Moses P.K.Avonyon And His Easy Life Twelve Stars Egun Band",
  "The Capitals International Band",
  "Suberu Oni And His Why Worry Orchestra",
  "Raimi Atolagbe And His Apala Band",
  "Mazi Vinny Obi, Pepsis Sound Fiesta",
  "Omo Smart Idonigie And His Happy Moving Stars",
  "Ojoto Boropikumo & His Izon Kenewenemo Band Of Tamigbe",
  "Akataka Onwalu (Highlife Superstars) And His Samba Sound 80's",
  "International Super Jet Band",
  "Ogidi Brother's Dance Band Of Kaima",
  "Engineer Ebidou Tebepah And His Ayele Philosophers Of Opokuma",
  "Chik Honey And His Oha-Ozara Band",
  "Ogbopuru Brothers Professional Band Of Odi",
  "Olubowale And His International Apala Facetious Band",
  "King International Band Led By Sonny Brown",
  "Austin Ezenwa",
  "Mrs. Ball Miekoromo And Her Ijaw Brothers Of Ayakoromo Nigeria",
  "Akin Euba And The Eleketo",
  "The Young African Brothers",
  "Imgbela Brothers International Band Of Odi",
  "Silva Special & His Jollymakers International Band",
  "Udo Ka Nma Band Of Owerri",
  "Aloma International Band",
  "Otokiti And His Seven Brothers",
  "Igho-Olokun Club Benin City",
  "Christie Adiele",
  "Osaro Nomayo",
  "Isoko Rasta Generation",
  "St. Augustine",
  "Isaac Eguasa & His Shining Rocket",
  "Chief Elemure Ogunyemi And His Native Blues",
  "Osamwonyi Osemwota & His Ugho Group",
  "Ese Osasere Union Dance Band",
  "Ocean Bummy Band",
  "Dan Orji",
  "Sunday Ogbulie And His Busy Bees",
  "A.E. Amgbaduba And His Okrika Amazuo's Band Of Nigeria",
  "Kehin Ajagun And His Melody Makers",
  "Ify Orajekwe",
  "Onyechere Chime International Super Stars",
  "Akke International Guitar Band Of Nig",
  "Okili Cultural Group Of Aboh",
  "Atani (Arochukwu) Cultural Group Lagos Branch",
  "Ahunaebe Yeli Dancing Group Of Okpanam",
  "The Jag",
  "Libini",
  "Josy Gboye And His Top Dandies Band",
  "Osayomore Joseph And The Creative",
  "The Golden Hits Of Prince Sheye Olagbegi & The Alphamigars",
  "The Sea Birds",
  "R&S Dance Band Of Idheze Isoko",
  "Umukwata Elegance Band",
  "The Young Stars",
  "Mojeed Ajani And His Apala Group",
  "Nneji International Band Of Nigeria",
  "G. Obinna Azubuike",
  "Ofu-Obi Social Club The United Voice Of Abagana",
  "Nnadozie Ogbumba & His Odinala Supersonic",
  "The Exodus Gospel Singers",
  "Eme Ballantyne",
  "Emily & Umu Ada Imo",
  "Homzy Trio Group",
  "Major Oguruwey Nwa Anokam And His Comics Of Nigeria",
  "Ejire Shadua & His Fuji Funkadeliks",
  "Goddy Igidigi",
  "Miliki Prince Yemi Kuti",
  "Kayode Fashola And The Music Makers",
  "Femme Fatale",
  "Michael Jackie",
  "Murphy Williams",
  "Wonder Dayo Kujore And His Unity Brothers Band",
  "Akim Takim",
  "Commandar Adoroji And His Guitar Band Of Okoloba",
  "The Ceejays",
  "Alhaji Minister & His Peters Of Umuleri",
  "Mac Gregor",
  "Christy Essien Igbokwe",
  "Tee Mac",
  "Chief Stephen Osita Osadebey & His Nigerian Soundmakers International",
  "Ikenga Superstars of Africa",
  "Christy Ogbah",
  "Esbee Family",
  "Toby Foyeh",
  "Tunde Mabadu",
  "Tirogo",
  "Arthur Freds",
  "Commy Bassey",
  "Sweet breeze",
  "Oriental Brothers International Band",
  "C. Heavy Weight Nkannbe",
  "Bola Johnson",
  "The Semi-Colon, Afro-Euro Ajunkwa",
  "Banico International",
  "Alhaji Amuda And His Safari System",
  "Sobaloju Akende-Ena & His Dada-Kuwada Brothers",
  "Jake Sollo",
  "Famous Star Dance Band of Akeddi River State Nigeria",
  "Osasco Brothers Band",
  "Orieji System King Silas Ikorodu Melody And His Ikoro Sound Makers",
  "The Believer's Rock Band",
  "KG Band",
  "Uselu Drivers Unions Band",
  "Seven Brothers Egun Band",
  "Alhaja Yewande Agbeke",
  "The Soca Band",
  "The Great Heroes International Band Of Owerri Imo State",
  "Raphael Omiye And His International Brothers Band Of Nigeria",
  "Saliu Allao And His Apalla Band",
  "General Prince Adekunle And His Western State Brothers",
  "General Adebayo Aremu And His Rolling Fuji System",
  "Durojaiye Aremu And His Original Adamo Brand",
  "Agbada Owo",
  "Prince A. E. Amgbaduba And His Benrivs Band Of Nigeria",
  "Orchestra Supper D.D Xpo-80 International",
  "James Dayonson And The National Sounders",
  "Isaac Way Key Sounds",
  "Gregory Akuma Of Nnokwa And His Planners Band",
  "Sidikatu Abeni & Her Waka Group (Igbogun)",
  "Kayode OjoAnd His Top National Band",
  "Egbemo Top Hitters Band Of Ogbu-Dugbudu",
  "Y. K. Ajadi & His Apala Melody Band",
  "King 1. Abua And His Young Stars Band",
  "Lobito Aghahowa And His International Brothers Dance Band Of Benin",
  "Thony Adex ANd His Reformed Sedico Band",
  "Alhaji Minister Ans His Peters Of Umuleri",
  "Skylarks International Band",
  "Denis Ford and The Students",
  "Alhaji Saka Olayigbade And His Adara System",
  "Mr H And Friends",
  "Hubert Ogunde And His Studio Band",
  "Manford Best",
  "Fred Zindi",
  "Chief Commander Ebenezer Obey & His International Bros",
  "Grace Ekpeyong",
  "Hajia Kulu Lafiagi And her maami Group",
  "Remote Control",
  "The Young Stars Of Weppa Wanno",
  "Mighty Maytones",
  "Godwin Anono",
  "The Bintos Of Etsakor",
  "I.G.",
  "Tony Akhaile & His Music Crackers",
  "Pat Alonzo",
  "BML Chapels",
  "Bread & Butter Band",
  "Celia Gladys",
  "King Peter Mbarga",
  "Rev. Jully Horms & The Univers",
  "Black Gold",
  "Kamaru Kazeem And The Lovely Fuji Exponent",
  "Effi Duke & The Love Family",
  "Mustapha Umaru & The Brights",
  "Big John",
  "Waza Collection",
  "Original Brothers Band",
  "Beta Yama",
  "The Twins",
  "Rock Town Express",
  "Jimi Lee, Mona Finnih, The Sensationals",
  "Akinrichards & Executives",
  "Lijadu Sisters",
  "Felix Lebarty’s Gang",
  "Joe Moks",
  "General Prince Adekunle & His Supersonic Sound",
  "Osai & The Orchestra Momah International",
  "Alex Tony Okoroji",
  "The Cross River Nationale",
  "Peter Effiom & His Idorenyin Group",
  "Lucky Brothers",
  "The Friimen",
  "T-Fire",
  "Umunze Brothers of Ukwuani",
  "Kasumu Adio and His Apala group",
  "Expensive Olubi",
  "Ikwueke Super Of Nigeria",
  "Adamosa Osagiede And His International Brothers Band",
  "Mighty Flames",
  "Emmanuel Aghedo And His Boyo Dance Band",
  "Nfon Joe-Joe",
  "Eboa Moukouri & His Super Dada Orchestra",
  "Christian Ibekwe & His Ikoro Kings",
  "Ugbo And His Melody dance Band",
  "Ezeti Brothers International",
  "Chuks Dandy International Orchestra",
  "Fela Igbinovia And His Echo Band",
  "Raphael Ngoumou",
  "The Social Circle Orchestra",
  "Tony Allen, The Afro Messangers",
  "Tony Allen, The Africa 70",
  "Sonny Oti And His Band",
  "Demmy Bassey & The Cannan Stars",
  "Aja Brothers International",
  "Otu Ife Onyemem Cultural Group Of Onitsha-Ugbo Lagos Branch",
  "The African Gospel Singing Philosophers",
  "Ezikie Uti & Nigeria Music Makers",
  "Aigbe Lebarty",
  "Sunday Ezute",
  "Prof S. N. De Opia & His Melody Dance Band",
  "Crosdale Juba And His Blue Echos",
  "S. O. Zacchaus",
  "Raimi Ishola And His Apala Group",
  "Sunday Samuel Osuoha",
  "Gold Convention",
  "E.E. Akpan & His Hunters Dance Band",
  "Union Brothers Dance Band Nsukka",
  "The Hansom High Life & Funk Band",
  "Ade Ayoola ANd The 20th Century Disciples",
  "Edosoghe Union Egboko",
  "King Martins Nkeh And His International Band Of Africa",
  "Sir Shina Adewale And His Super Stars International",
  "The Score Music Power",
  "Jah Children",
  "The Sea-Men International",
  "The Young Molokwu And His Group",
  "Cosy Man Joe",
  "St. Joseph And His Alunge Super Jazz",
  "Finger Brothers International Band Of Imo",
  "Queen Iyamah And Her Ikpesi Group 'Oigalume'",
  "Okop Unenike Group",
  "Papalolo",
  "Pogo Limited",
  "State City Singers",
  "Egbeyemi Native Band",
  "Sunday Irabor & His Moon Shine Dance Band",
  "The Boy Cattiba Ejime And His Lidos International Band",
  "Aibor Bello And His Aloagbaye",
  "Florence Ogbeide And Her Easy Lives Band",
  "Etim Akanyak",
  "Kosmik 3",
  "Deciders",
  "O'Dili",
  "Family Choice",
  "Moukouri Richard",
  "Muniratu Abeo & Her Waka Group",
  "Wachukwu Oligo and The Fiesta National",
  "Leo Enwere And His Professional Jazz Bell",
  "Bob Oku",
  "Saint Emmy",
  "Sony Enang",
  "Madona Obiagwu",
  "Ubogazi I Super",
  "General Wahabi Ilori And His Destiny Fuji Sound",
  "Black Children Sledge Funk Group",
  "Mike Ezeudo And His Standard Music Maker Band Of Nigeria",
  "Osunfisan Brothers & Sisters",
  "City Brothers Band Of Owerri",
  "Sir Chuks & His Royal Sound Makers",
  "Eddy Ifebigh",
  "Udensi Agwu And His Rainbow Stars",
  "Berkely Jones",
  "Chukwuemeka Madukwem And The Big Seven",
  "Super Ambiance Orchestra",
  "Smart Iyk",
  "Jimmy Sky & The Gaylords",
  "Chukwudi Nwafor",
  "Captain Jide Ojo And His Yankee Show International",
  "Opotopo",
  "Captain Jide Ojo",
  "Chioma International Band of Owerri",
  "Charles Iwegbue & His Hino Sound",
  "RAMC Movement (The African Band)",
  "Fabomo Edoleyi And His Supersonic Sound Of Nig.",
  "Oluremi And His Young Star Band",
  "Ammy August",
  "Jafag 5",
  "Thony Shorby Nyenwi",
  "Tettey's Band",
  "Grotto II",
  "Apples",
  "Ofege",
  "Yusufu Olatunji & His Sakara Group",
  "Patrick Idahosa & His African Sound Makers",
  "Tunde Mabadu & His Sunrise",
  "Nicholas Opara & His Osinachi Brothers",
  "Chris Ibekwe & His Ikoro of Africa",
  "James Iroha",
  "Paulson Kalu & His Stars 25 International",
  "Hit-Home Brothers Band of Owerri",
  "The Winners Band of Africa",
  "Abaraka ‘77 Guitar Band of Nigeria",
  "Elvis Maco",
  "Friimen Muzik Company",
  "Jimmy Tony Awoma",
  "Mary Afi Usuah",
  "Emman Igwebuike And the Black Tops",
  "Ehengbuda Cult Members",
  "SJOB Movement",
  "The Akaeze's International Band",
  "The Black Hippies",
  "Oyoyo Ebimogana Guitar Boy",
  "Innocent Ukpere, The Younger Brother International Band",
  "Picollo F.E",
  "Kayode Ige",
  "Professional Wood Pecker's Of Nigeria",
  "Alhaji Fatayi Olowonyo And His Native Guitar Band",
  "Danny Kay Oluodo",
  "Bayo Onile And His Golden Brothers Band",
  "Piusal Ukokorode And His Blue Band",
  "Ipme Dance Band Eguae-Evbohighae",
  "Triple 'S'",
  "Okechukwu Nwatu",
  "Terry O'Nix",
  "Sonora Gentil",
  "Success Dance Band Obiaruku",
  "E.C.W.A Church Iwo Voice of Christ",
  "Sali Elesin-Nla And His Dundun Sekere Band",
  "Benlad May And His Chorus Kayode Osofisan",
  "Willie Okoro And His Group Of Okolo",
  "Joseph Mumbari",
  "Sea Lions",
  "Choral Group Umuahia",
  "Odogwu Ajility & His Officials",
  "Oluremi & His Sakadelly Beats",
  "Oluchi Brothers International Band of Nigeria",
  "Dan Satch & The Professional Atomic 8 Band",
  "Sunny Afuba's International Band Of Nigeria",
  "J.A Agboola & His Beaming Star",
  "David Ayadju & His Group Of Agbarha",
  "S.F. Olowo Okere & His Federal Night Eagles Band",
  "Edo Cultural Group",
  "Burstic Kingsley Bassey & His Professional Pioneers Dance Band",
  "Bayuratu Abebi And Her Waka Band",
  "Otigba And His Brothers Band Of Nigeria",
  "Addis-Ababa Okoro And His Tempos Dance Band",
  "Godwin Igidigi And The Top Impressions",
  "Tessy And Kate",
  "Tommy Bay Obasuyi & His Aleghe Beat",
  "Raji Owonikoko & His Apala Group",
  "Helen Williams & The Young Timers",
  "Prince Eneh & The CIB Of Nigeria",
  "Godwin Omabuwa & His Cassanova Dandies",
  "I Man Johnson",
  "Rich Rich International Jass",
  "Chief Counselor Twins Seven Seven And The Artistic Adventures",
  "BAC Foundation",
  "People's Thing Group",
  "Oladele Ojoade and His Omuro System",
  "Eastern Star Dance Band Aba",
  "Mad Man Jaga",
  "Christy And Lee",
  "Adebayo Omo-Oja & His Ondo State Band",
  "Nigeria Rockers Band Of Ashaka",
  "Bob Aladeniyi And His Juju Rock Stars",
  "Abana And His Group From Okpella",
  "Ase Brothers Dance Band Imiekuri-Okpella",
  "Prince Omo Lawal-Osula & His Ebonoto Sound Exponents",
  "Ogbomgellege Group Aboh Mbaise",
  "Arugo International Band Of Owerri",
  "The Black Heroes",
  "Mukuri Jazz Band",
  "Felix Igbinadolo And His African Dance Band Of Nigeria",
  "Okongwu B.B.B & The Silver Brothers",
  "Memuna & His Brothers Supper Dada Jazz Band",
  "Denge & His Social Group Of Ihevbe",
  "Eddy Okonkwo & His International Band",
  "Asara Technical College",
  "Anyi Chukwu Oshimili And Sons",
  "Celestine Ukwu Memorial Band",
  "Founders 15",
  "Kos Omohimin & The Gongs",
  "Sammy Akpabot",
  "The Heart Breakers",
  "Gosa Idehen And The Struggling Men",
  "Oko Cultural Group Lagos",
  "Oba General B.O.C. Ogbodu & His Super Angels",
  "Sunny Elvis",
  "Odee Elosiebo",
  "City Brothers International",
  "Paramount Stars Of Nigeria",
  "Goody Ezike And The Ambassadors In His Omajiji '70s",
  "The Natural '7' International",
  "Dr. Onyeaba",
  "David Ariyo alias Kokori",
  "Amorji Ogonogo Akpugo",
  "C.S. Crew",
  "The Sound Mongers",
  "Future Hope International Dance Band Of Nigeria",
  "Dolphin Brothers International",
  "Gimagosa Idehen Karetaredo Heroes",
  "Ogbansiegbe Onuora And His Star Brothers Band Of Nigeria",
  "Chukwuemeka Manu And His Advisers",
  "Gladys Mokezie",
  "Dennis MacDavid",
  "Dan Sonny Odogbo And The Iyoddos",
  "Sir Lawr - City Okafor International & His Oshokolo Melody Band",
  "Otu Onye Zusia Nwa Of Akwu Igbo",
  "Eddy Polo",
  "The New Generation",
  "Marvin Kingsley",
  "The Young Stars International",
  "Atomic Jazz Of Africa",
  "President Aromatadi And His Atlantic Band",
  "The Mercuries Band",
  "Demos Deniran & His Dynamic African Rhythmers",
  "Ukpetor Brothers International",
  "The Elaborate Sound Echoers",
  "Sir Joe Peller And His Oduedue",
  "Kudiratu Agbabiaka And Her Waka Group",
  "Sekus Men Jazz Band",
  "Aura",
  "Geraldo Pino",
  "Drivers Union Dance Band",
  "Spirit Konekson",
  "Asiko",
  "Moneyman, The Super 5 International",
  "Alhaji Minister And His Peters",
  "Orchestre Bota International",
  "Gentleman Ofele, Botta International Orchestra",
  "Kiki Gyan",
  "Joni Haastrup",
  "Tony Grey & The Black Kings",
  "Geetee Onwuka & The Odumeze Dandies",
  "Stoneface Iwuagwu",
  "Geetee Onwuka",
  "Cross River Nationale",
  "Abaraka ‘77 Original Band of Nigeria",
  "The Vibrations",
  "Super 5 International",
  "Goddy Okoro and His Conclusion Superstars of Africa",
  "Prince Kola Adekunle And The Reformed Western Brothers Band of Nigeria",
  "Tony Barrett, SJOB Movement",
  "Amoji Brothers International of Onicha Ukwuani",
  "Show Promoter and His Dancing Boys",
  "Christopher Asah & His Seven Seven Egwu Ekpili Group Of Enugwu Ukwu Njikoka",
  "Bob Shinner",
  "Hon. Agans Omoregie And His International Beat",
  "Billy Bassey And His Peace Makers",
  "Agbeboaye Oseghale's Dance Band",
  "The Barbados of The World",
  "Ojemba Enweilo Band of Eziowelle",
  "Saliu Okobo And His Group",
  "Sir Arch Bishop Romero and The Professional Romeo Success of Africa",
  "Orobosa Dance Band",
  "The Springs Band",
  "Egwu Izaga",
  "Okechukwu Nwatu & His Group",
  "Abayi Jazz Band Of Ojobo",
  "St. Michael Obi Obanya",
  "Orchestra Super Dada",
  "Professor I.K Belemu And His Melody Dance Band Torugbene Nigeria",
  "S.A Olagunowo",
  "Alhaji Akanji Olorun 'O Jo Nwon And His Group",
  "Smokey 007",
  "Robinga Ossai And The Bendel Brother's Band",
  "Pesco Edosa & His Sound Makers",
  "Robinga Ossai Ojenokwu Sonds Of Africa",
  "The Saints",
  "Bosun Sowande",
  "Michael Ekomwenrenren And His Eko Dance Band",
  "The Light Bearers",
  "Okok Rachar Band 77",
  "Atagba And His Oniya Group",
  "Wahabi Shekoni Memorial Sakara Band",
  "Jimmy Conter",
  "Eribra Chico And His Mellows Professionals",
  "The Champions International",
  "Eleven Eleven Ekpili Supersounds",
  "Voices Of Benin",
  "Handsome Rebel And The Honey Bunch",
  "F. J. O. Akokono ANd His Supersonic Heavy Gravels",
  "Ego Orajaka",
  "Samson Irabor & His Top Sound Men",
  "Danfo And His Native Blues",
  "Yenrinmene Brothers & Their Voices Of Gbekebor",
  "Aghazodebande Akpata Group",
  "The City Boys",
  "Aimiyekeagbon Ogbebor & His Akpata Group",
  "Ademola Asiru & His Etiyeri Group",
  "Nawfia Ladies",
  "The Sobaimeku Sounds Internationale",
  "Audience Limited",
  "Ifeanyi Gbenoba And His Studio Dance Band",
  "Ighofose Brothers International",
  "Energy & The Young Stars",
  "Omojadow Enobakhare & His Royal Dance Band Of Nigeria",
  "Otu Ofu Obi Of Ibusa Benin Branch",
  "Dandy Od Boy And The Jumbos Band",
  "Soukous Nº 1 Of Africa",
  "Super Wings",
  "United Sound Makers",
  "Yakubu Akanji And His Apala Group",
  "The Kings International",
  "Hails Angels Super Band",
  "Foundars 15 Rock Group",
  "Akwuba Nwoye Azodo And His Egwu Ekpili Group Of Awkulu Anambra",
  "Mansion",
  "Edwin Obochi & His Afro Blues Rhythms",
  "Benjamin Otarus",
  "Super Rock",
  "Uba Dibango & His Oshanga Super Stars",
  "Idahosa Obazee & His Group",
  "Aktion",
  "Chief Brigadier Olu Oni & His marathon System",
  "Prince Odisi Mounkurai & His Kingfisher Band International",
  "Tunde Williams",
  "Pap Jonas And The Realistics",
  "The Identicals",
  "Her Majesty Queen Oladunni Decency And Her Unity Orchestra",
  "The Ambassadors Band",
  "Chimsco International Dance Band",
  "Survival",
  "Ozobby Horn",
  "Tabanku (University Of IFE Students)",
  "King Ntia & Jungle Beats",
  "Lady Balogun",
  "Disco-Blaze",
  "Idheze Youngsters Band",
  "Umukwata International Brothers",
  "Ondigui, Bota Tabansi International",
  "The Funkees",
  "Prince Nico Mbarga & Rocafil Jazz",
  "Joe Nez & His Top Six",
  "Friimen Muzik",
  "The Jets Revival",
  "Jacob Oluwole and His Bonanza Rhythm Band",
  "Sahara All Star Band of Jos",
  "The Wings",
  "Awutolo & His Group",
  "Obodo Uka Mba And His Group",
  "RSM Erenogbo",
  "Hadji Fatayi Adio And His Inner Circle",
  "Captain Kelly Ola And His Lucky Stars Band",
  "Udekwe Omabala And His Group",
  "Celestine Ukwu & His Philosophers National",
  "Nwokolobia Agu Singers",
  "The Bible Men Of Africa",
  "Lammy Oguns And His Rock Mountain Band",
  "Igba Ijele Dance",
  "Udu",
  "Ethiope Band",
  "Johnson Adjan And His Golden Voice Of Orhokpo",
  "King Bob With The Popcos",
  "Lawrence Omoruyi & His Flamingo Dance Band",
  "Gold & S.N. De Opia",
  "Easy Kabaka Brown",
  "Obarisiagbon Cultural Theatre Of Benin",
  "Sir, Sunnywaya Ilevbare And His Group",
  "Obrete Asa Youths Aba",
  "Austin O. And The Austine Brothers Band",
  "Yemi Kuti And His Band",
  "Martin Nkeh",
  "Isiaka Alhaji And His Pankeke Group",
  "Rev. A. A. Inuks And His Cowboys Band",
  "S. Baba Waidi",
  "Narg Funk Machine (Nigerian Army Rhythm Group)",
  "The Super Bantous International",
  "Popular Iwere Ladies Club, Warri",
  "Professional Stars International Of Ubakala Umuahia",
  "Ima Edi Obio Group",
  "Asi Balewa And His Traditional Sound Hits",
  "Zeal Oniya And His Music",
  "Mary Sorrowful Singers Group",
  "The Osisi Of Africa",
  "Sir Kizitu Omon Imasuen And His Awanokhueni",
  "Abigbo Cultural Group Mbaise",
  "David Oboigbator And His Edo Swankers",
  "Unity Band Of Warrake",
  "A. K. Omokunu",
  "Nnamdi Olebra And Friends",
  "Nka Asian Nkparawa Ukana",
  "The Faces",
  "Arondizuogu Masquerade Club Aba Branch",
  "Sakasuku And His Group",
  "Shina Abiodun Bakare's Merenda Band",
  "Oba Youth Club Of Nigeria",
  "P.R.O (People Rock Outfit)",
  "Sebastine Oparaogu",
  "Olanrewaju Adepoju And His National Akewi Theatre",
  "Theresa Ofoje",
  "Okonkwo Asah & His Group Seven-Seven Of Enugwu Ukwu",
  "Rhythm Brothers Of Nigeria",
  "Anyamele Iwuchukwu And His International Brothers",
  "Danie Ian And The Spades",
  "Ohafia War Dance (Akanu Ohafia)",
  "Anya Super Stars Band",
  "Ola Balogun",
  "Otu Eze Dance Group",
  "Nelly Uchendu, Mike Obianwu",
  "Abaraka Guitar Band",
  "Harcourt Whyte and Choir",
  "Segun Bucknor",
  "Olufemi Ajasa And His New Nigerian Bros",
  "Abbey City And His Jolly City",
  "Ndoni Youths League of Onitsha",
  "Ajana Obiligbo And His Group",
  "Salawu Idofian And His Apala Group",
  "Alhaji Sanni Akanni",
  "Adebayo Aremu And His Ewi Pankenke Group",
  "Bisi Edalere And His Band",
  "Alhaji Amadu Senior And His Group",
  "Alhaji Sule Adeiku And His Group",
  "Elegbe Cultural Group Of Evbo-Esi Iyokorhionwon",
  "Chief Umobuarie & His Group",
  "Erasmus Jenewari And His Gospel Bells",
  "Idoma",
  "Eastern Minstrels De Prestige Entertainers",
  "Dele Ojo and His Star Brothers Band",
  "Fasco Dagama And His New Star Band",
  "King Sonny Brown And His Sailors Band",
  "E.A. Adex And His Metro Brother Band",
  "A.D Jekari Akpakita And His Group",
  "Tabukah X",
  "Messiah Okhome And His Group",
  "Witch Doctor Maso And The Masokoloko Explosion",
  "Omade Star Rockers Dance Band",
  "Ohio & The Weekend Chart Busters",
  "Natamatase Orogho Recording Group",
  "Sunday Igbinomwanhia And His New Moon Dance Band",
  "Aibor Bello And His Unity Band Of Afemai",
  "Hunge Gbededo And His Egbe Igbimo Group",
  "Ojo Ojurongbe Ofe & His Ijigi Group Of Ondo",
  "Z. A. Oyebisi And His United Brothers",
  "Sukafunk Of Africa",
  "N.B.C. Choir Enugu",
  "Chief Kunle Ade And His Electro Sounds",
  "Mavilious Dance Band Of Nigeria",
  "Evelyn Dance Band",
  "Godwin Ironbar",
  "Enugu Musical Society",
  "Perry Ernest",
  "General Wale Glorious Memorial and His Aiye-Soro Spot Band of Akure",
  "Dele Ojo & His Africana Internationals",
  "Ikediala & His Tropical Stars",
  "Fela Kuti, The Africa '70",
  "Idirisu Egbebi & His Alo Group",
  "Tony Grey & The Black 7",
  "The Youths Of The Universe",
  "Lawal Osula And His Alubogie Sex Bombers",
  "David Ojus And His Commercial Dance Band",
  "Tunde Nightingale",
  "Alhaji Sale Maigambra Sokoto",
  "Ife University Theater",
  "Chief Atewolara And His Famous Band",
  "Shola Olu & His Merry Maker's Band",
  "Shango Dance Band",
  "St. Michael And His Oboshi International",
  "Eremina Ogbo",
  "Sir I.B. Oriowo & His New Nigerian Melody",
  "Yemi Babington And His Staff Band",
  "Seyi Osunfisan",
  "M.F. Olayinka And His New Stars Band",
  "Sir I. B. Oriowo & His New Nigerian Melody",
  "Mosey N",
  "Tony Grey & The Magnificent Zeinians",
  "Idele’s Hurricane Dance Band",
  "Kola Ogunmola",
  "The Nkengas",
  "Bola Johnson And His Easy Life Top Beats",
  "The Mebusas",
  "Sonny Lion Heart",
  "World Affairs",
  "The Wailers",
  "Mike Toma",
  "Freddie & The Formulars",
  "Dele Show & His Rhythm Dandies",
  "Sir I. B. Oriowo & His Niger Star Dandies",
  "Dadigaye And His Friends Of Distinction",
  "Eddie Okwedy",
  "Manu Dibango",
  "Cardinal Rex Lawson & His Rivers Men",
  "The Don Isaac Ezekiel Combination",
  "Orlando Julius and His Afro Sounders",
  "Admiral Olalekan Olorode And His Lagos Day Orchestral",
  "Eddy Okwedy And His Moores",
  "Ayinde Bakare & His Orchestra",
  "Master Guitarist Sunny Ade & His Green Spot Band",
  "Wale Glorious & His Aiyesoro Spots Band",
  "Millie",
  "The Hygrades",
  "Commander-in-Chief Stephen Osita Osadebe & His Nigerian Sound Makers",
  "Fela Ransome Kuti and His Africa ‘70",
  "Salami Atanda and His Apala Group",
  "Jimoh Ojindo And His Group",
  "Easy Life Dandies",
  "Ebengho And His Dance Band",
  "Bro. Uzochi Njoku",
  "Chancelor Eniola And His Yellow Pillars Band",
  "Mac Dessy Adult",
  "Bello Atanda And His Apala Group",
  "Salami Balogun And His Sakara Music Band",
  "Douglas Osakwe And The Abobokos",
  "Sir Victor Uwaifo & his Melody Maestroes",
  "Cardinal Rex Jim Lawson & His Mayor's Band Of Nigeria",
  "Yusufu Olatunji and His Group",
  "The Nigerian Police Band Force Seven",
  "J.A. Adedeji",
  "Fela Ransome Kuti And His Koola Lobitos",
  "Supreme Banjo Adegbola And His Merry Brothers Band",
  "The Mohawks",
  "Ginger Folorunsho Johnson",
  "Uncle Femi John And His Africana Dance Band",
  "Adebukonla And Her Group",
  "Olayiwola Oginni And His Young Baby Face Band",
  "Chief S.I Atolagbe And His Holy Rainbow Band",
  "Tex Ubong",
  "F.A Jimmy West And His Rosy Morning Band",
  "Charles Iwegbue & His Archibogs",
  "Bobby Benson & His Combo",
  "E.C. Arinze & His Music",
  "Charles Iwuegbue & His Archibogs",
  "Umunze Dance Band of Abedei",
  "Wonder Boy And His Youngsters Band of Africa",
  "St. Augustine, The Garrisons of 187 Infantry Bn Dance Band Nigerian Army",
  "Natty Jas And His Famous Dread",
  "The Parrots",
  "Talented O.C.B Ade and His Jimbas Unity Band",
  "Sunny Efe",
  "Friend Thompson",
  "Popular Tajudeen Akanmu And His Zorro Top Hitters",
  "Dr Josy Ade And His Social Band",
  "Michael Mpamah",
  "Mayor Sule Agboola And His Moonlight Spot Band",
  "Ebekule Brothers",
  "Kenny Eze",
  "King Sonny Brown And His Diamond National",
  "Major Femi Ariyo And His Nigerian Beats",
  "St. John's International Band Of Africa",
  "Dr Juliot John",
  "Sir Lemi And His Anioma Brothers",
  "Emma O And The O Family Band",
  "Rosary Crusade",
  "Chief Ologun And His Natives Blues",
  "Agu Masquerade Group",
  "Major Minor Vibrations",
  "Omo Sunday And De Ezemwenghian 75 Of Ogan",
  "Biliaminu Ayinde And His Morning Star Fuji Group",
  "The Dews",
  "Lovely Youths Band",
  "Mukaila Adisa Excellency And His Faaji Super Sound",
  "D. K. C Lagos & His Reformers Band Of Nigeria",
  "The Shiloh Echoers Of Seventh-Day Adventist Grammar School Ede",
  "God Mercy Dance Band",
  "G. S. Konboye And The God's Mercy Dance Band Okpokunu",
  "Steven Amechi & His Rhythm Skies",
  "Madam Azagueni Aghahowa And Her Group",
  "Emecca Express Organization",
  "Sir Ferry Handsome & Galaxy Band Of Africa",
  "Chief Igbuzor Emenenkeni And His Group Of Orogun",
  "Dr Michael I. Marcus And His Hopeful Friends Band Of Nigeria",
  "Raimi Tikalosoro & His Apala Band",
  "De Captivators",
  "D. K. Adu And His Group",
  "Kamakajuru Onwuanibe And His Emekuku Brothers International Band",
  "The Music Makers",
  "Mike 'N' Gobis Brothers Band",
  "Bob Laykway And His Wealers Dance Band Of Somorika",
  "Kidzu Joe & The Jungles",
  "The Youth Of Etsakor",
  "Otu Ife Aka Onye Cultural Group Of Idumuje-Ugboko",
  "The Jofabro Star Aces",
  "Patrick Ojo And His Ukwuani Dance Band",
  "F. A. Akintade & His Group",
  "Godwin Aletor And The Talents Of Ukpebho",
  "The Syndicates",
  "Prinsec Kingsway D. Kakiyere",
  "Iguedo Brothers Band International",
  "Alhaji Muhyideen Bello Ajani",
  "Sammy A.",
  "Bros Solomon And Godson",
  "Pastor J.A Adelakun & The Ayewa International Gospel Singers",
  "Jimm-Hys",
  "The Great Ojoge Daniel And His Modern Orchestra",
  "Emeka Okoye (Jimmy Wiper) And His Super Band",
  "Ben Jagga",
  "Obisolo (Simon Obi)",
  "The Pick-Ups",
  "Ishola Ejina Tobeni And His Group",
  "Ayo International Gospel Singers",
  "King Josy Friday And His National Toppers Band",
  "Panam Percy Paul",
  "Addisababa Okoro",
  "De Prince",
  "The Pilgrim Voices Lagos",
  "De Roots",
  "Stormwind",
  "Mac Williams Fundoh",
  "Tundey Bright",
  "Eddie Uzoma",
  "Lade' Kong",
  "Kabiniudo International Dance Band Of Nigeria",
  "St. Jaspa",
  "Prince Chinedu F. Iwuozor",
  "Ewheyanudje Otubure And His Star Band Of Orhakpor",
  "Orata Super Stars International",
  "Aghanti Nwaraba",
  "Olapade Agoro & The Singing Stars",
  "Venite Emeribe",
  "Commander COC Nze",
  "National Youth Service Corps",
  "Kegban Sofiyea And His Melody Music Lovers",
  "Nlemibe Man-Willis",
  "Ai-Jay Gal",
  "Silas Echefu & His Vatican City Singers",
  "Chinaemere Sounds International Guitar Band Owerri",
  "J.C. Aken & His Lelewas International Rock Band Of Nigeria",
  "Christopher Mbadugha",
  "Tony Onyejekwe",
  "Boss U.F.C Francis",
  "Muraina Akinbami & His Sakara Group",
  "Christian Aguguam",
  "Sonny Sonny",
  "Christ Holy Church Central Choir",
  "Toby Anady",
  "Samson Aigbovo And His Ugie Hitters Of Benin",
  "The Echoes Of Melody",
  "Reggae Doctors",
  "Chuks Eddy",
  "Bob Sir Merenge & His Igbo Cultural Singers Uli Anamura State Of Nig",
  "Man-Delars & Reggae Band",
  "The Invadeers",
  "Owerri Women Association",
  "Ehiri Musical Thriller's Band Warri",
  "Holey Kingsley And The Groovy Diamonds (In Esile Cultural Songs)",
  "Alhaji Ramoni Akanni And His Fuji Exponent",
  "Obinkita Arochukwu Cultural Music Group",
  "Tina Tobias",
  "Baba Uwa Ekpere Chuckwu Musical Party",
  "S T F Gang",
  "Minister Olu Aje And His City Spot Band",
  "Adebayo & His New Spots Band",
  "Ganse Gonti And His Newstar Egun Band",
  "Alleluya Singers",
  "Goddy Na-Ku-Nkwa And His Odinana Singing Party",
  "Captain Akanbi Elewu-Etu And His Ehuzu System Band",
  "Myke Moul",
  "Kris 'N' Deniz",
  "Eghe Nova",
  "George Lover",
  "The Root Ambassadors",
  "Timmy Nebuwa",
  "S. M Ikpeazu & His Blue Omabunike Musical Group",
  "Goddy Nwa-Iwu",
  "The Young Cardinal",
  "The Flying Horses Dance Band",
  "The Mercy Singers",
  "Iyanda Enah & His Pakenke Group",
  "Rasta Pat Ejoh",
  "Chukuyenum Okpor Smart",
  "Ozugbo Martyns",
  "Prince Isaac",
  "Daniel E. Agboro",
  "Raz Bonez",
  "Imo City International Guitar Band",
  "Sir Qkechukwu Azodo, Ikoro Band System of Nigeria",
  "Donald Ekenta",
  "Obi Onwubuariri",
  "Kay Dee Juju International",
  "E. Echendu Nweze",
  "Alhaji General Monsuru Akande And His Fuji Reformers Band",
  "Daddy Adamosa & His Cosmos Composers",
  "New Niger City Dance Band",
  "Kings Nwabueze And His Kossa Sound",
  "Thony Adex And His Melody Makers",
  "Show Boy Ferry Handsome And His Brothers International Band",
  "Jimoh Ajani And His Patoto Group",
  "Showboy 'Feryhandsome' Egwu & His Ukpeto International Brothers Super Three",
  "Ododo Atilogwu",
  "The Monitors Band",
  "Andy 'N' Soul",
  "Ras Joe & De Preachers",
  "Sparkar Ohiokhaobo & His Afenmai Pioneers Dance Band",
  "The United Brothers Music",
  "Ojo Okeji",
  "Yom-Yem",
  "Tony Darlington",
  "Johnny 'O. Bazz",
  "Oshodi Combos Band",
  "Rock Robesco Airiomwanhimwen Music Star",
  "Sons Of Izzu",
  "Okey Ozed",
  "Rickie E",
  "Amel Addmore",
  "Okwuneche Enyi Igboariam",
  "Lady Franchet",
].sort((a, b) => {
  let fa = a.toLowerCase(),
    fb = b.toLowerCase();
  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
});

export const DesignerList = [
  "Sijibomi Aribidesi",
  "Babatunde David",
  "Anthony Azekwoh",
  "Bryan Christopher Moss",
  "Tobi Ajiboye",
  "Yusuf Sanni, \"Babatunde '24k' David\"",
  "Olaniyan Oluwapelumi",
  "Temzeey Calebs Temilorun",
  "Bidemi Tata",
  "Sinalo Ngcaba",
  "Niyi Okeowo",
  "Kayode Yajuar",
  "Soldierboyfriend",
  "Obedshotit",
  "Sore Adebisi",
  "Slawn",
  "7ifetheillustrator",
  "Daniel Obasi",
  "Duro Arts",
  "Fortune Young",
  "dricky",
  "Dunsin Bankole",
  "bolthedesigner",
  "Kingurantatata",
  "Animisiewasz",
  "Zhenia",
  "Upson Martin",
  "Totem Studios",
  "sniip3r",
  "Candice Lawler",
  "Lanre Williams",
  "JEXWAYS",
  "ADEDOLAPO OMO AJIBOYE",
  "Jackson Fubara",
  "icree8media",
  "Ruby Okoro",
  "Uche Jins",
  "dayocyrus",
  "Osbalt",
  "Weird Creative",
  "vizuvlgvds",
  "Iyebiye Adeitan",
  "Victor Mkpo",
  "R E G G Z",
  "Nneka Egbuna",
  "OLAMIDE OLABODE",
  "kieron boothe",
  "Mizanobel Makanjuóla",
  "Chinonso Wilfred",
  "artsbyjaxen",
  "Lawrence Koby",
  "Inkz Workx",
  "Mikeyoshai",
  "Bolade Banjo",
  "Isabelle Irabor",
  "IAmWillys Art",
  "Rozay The Villain (ULTRA)",
  "Odion Tobi",
  "CKay",
  "Sunad Printers, Lagos",
  "Angeloh",
  "Nocamowashere",
  "Abraham Junaid",
  "Sean Patrick",
  "Muhammed Adepoju",
  "Sketxh",
  "Demont Pinder",
  "Graphixed",
  "Bayo Brahms",
  "Aseel Makarem",
  "Clinton Fayomi",
  "Adeolu Osibodu",
  "Notion 34 and Tolu Toon",
  "MANU PILLAI and Julia et Vincent",
  "Tolu Toon",
  "Arts By Nonso",
  "Mufasa Studios",
  "Qvxdri",
  "Ibra Ake",
  "Ayodeji Sokunbi",
  "Funto Coker",
  "Obidigbo Nzeribe",
  "Abinibi",
  "Fawaz",
  "Lakin Ogunbanwo",
  "Olowomeye Samuel Ancestor",
  "Duks",
  "Christopher Afolabi",
  "Ovie Mac Solomon",
  "Straffiti",
  "Oluwatxbi",
  "Deji Alaka",
  "George Irabor",
  "Afolabi Olalekan",
  "FallofMichael",
  "KODI",
  "Joshua David Tara, Kayode Yajuar",
  "Tobi Johnson",
  "_I_a_m_1",
  "Tomi Thomas",
  "Renike Olusanya",
  "Sute Iwar",
  "Svstw",
  "Thompson S. Ekong",
  "Micheal Adekolu",
  "CRE8TVSOUL",
  "Tabs Press Ikeja",
  "Peter Okoloba",
  "Chuka Obi",
  "Jata",
  "Xtreeme",
  "Adey Omotade",
  "Albert Donkor",
  "Lemi Ghariokwu",
  "Sajjad Musa",
  "Musai Ganiyy",
  "Yinka Inker",
  "Ayanfe Olarinde",
  "Dayo Gyrus",
  "Ahmad Tarek Nour",
  "Tobi Ogunrinde",
  "Dolapo Adebayo",
  "RO The Visualist",
  "Yemi Funkypluto",
  "Suleiman Gwaddah",
  "Immaculate Taste",
  "Bishop Graphix",
  "Central Press, Benin City",
  "Khalifa Grafits",
  'Awoyemi "Reinye" Hafeez',
  "Ayo Obuhoro",
  "Eazy Bob Wizzy",
  "Akinlabi Akinbulumo",
  "Malcolm Olagundoye",
  "Godwin Onwe",
  "Kid Mellow",
  "Olawale Aremo",
  "Olawale Aremo & InkWorkz",
  "Fahd Fahdart Bello",
  "Sorple Cecil",
  "Anthony Ketuojor Ikediuba",
  "27th Street Entertainment Company",
  "Sunsal Ventures",
  "August Udoh",
  "Ghariokwu Lemi Arts Company",
  "Ubi Odemaina",
  "Koby Martin",
  "Obi Somto",
  "Mojarzido",
  "Williams Chechet",
  "Ibukun Adelekan",
  "Ibukunola Printers",
  "Little By Little Press",
  "Oghenejobo Denzel",
  "Musiliu P. Brimah",
  "Mister Rae",
  "XYZ",
  "Ogaba Onazi",
  "Mag Designz",
  "Georgi Georgiev",
  "Flamez Graphics",
  "Omc Graphic Studio",
  "Icrea8media",
  "GRD Designlab",
  "Record Manufacturers Nigeria Ltd.",
  "GoodeyE photos",
  "Emmy Pease Production",
  "Abraham Calva",
  "Media Pictures",
  "Adopekid",
  "9th April Nig. Ltd",
  "Chukwudi Ogbonnaya",
  "Osa Seven",
  "TCD Photography",
  "Ukaegbu Johnson",
  "Fatkas Designs Co.",
  "Multikraft Media",
  "Happyteddy Media",
  "Grande Surface",
  "Mesa/Bluemoon",
  "Acho Ajoku",
  "Ebele & Chinye",
  "Julian House",
  "Optimal GEMA",
  "Ptassi Graphics",
  "Nelsonjack",
  "DragonX",
  "TY Bello",
  "Barett Akpokabayen Studios",
  "Prussianblue",
  "Grant Graphics",
  "Associated Press of Nigeria",
  "Freelance Graphics",
  "Ogunajo Ademola",
  "Demola Ogunajo",
  "Adrien Betra",
  "Chris Capuozzo",
  "Afukorist",
  "Inflammable Ink",
  "Olumide Onigbogi",
  "Matt Thame",
  "Richard Evans",
  "Siobhain O’Shea",
  "Kento Graph Print",
  "K. Sholad Nig. Ltd",
  "Big Active",
  "Weyinmi Atigbi",
  "Dems Nig. Ltd",
  "Eddie Okere",
  "John Guanieri",
  "Ayangboyega Graphic Enterprises",
  "Afrodisia Records",
  "Tunde Kuboye",
  "Corporategraphics",
  "Joko Printers",
  "Mike Ona Chisunum",
  "Akkad Designs & Prints Production",
  "Peregrino Communications International",
  "Adam Traum",
  "Designer Dream Productions",
  "Ebikefe Arts & Symbols",
  "Bab Sheriff & Co.",
  "Exoucia",
  "Gab Belleh",
  "Ajao Bello",
  "Austin Ugah (Jnr)",
  "Howard Miller",
  "Michael M Stephano",
  "Oduma Graphics Arts",
  "Dokun Design Service",
  "Edwin Toby Azaka Nig. Enterprises",
  "Crescent",
  "James Kessel",
  "Grafikomp",
  "Studio Gilad",
  "Charlimo Graphic Practitioners",
  "Tobi Agbagwu Arts",
  "Dems Graphic Reproductions",
  "Niyi Ajayi",
  "Freelance Printcraft",
  "Poatson Graphic Arts Trade",
  "Otu Arts",
  "Lagos Prints Shop",
  "Soji Overe and Associates",
  "Kadsons",
  "Jenefiok Graphics Press",
  "Bola Aje Arts",
  "Ola-Ladi Printing Co.",
  "Niyimon Photos",
  "Sunfun Publicity",
  "Great D's & Associates",
  "Charles Ayemere Imoloame",
  "Mick Advertising",
  "Abuede Ind. Ltd.",
  "Prince Weekender",
  "Diyaolu Oladipo",
  "Philip Trimnel",
  "Osagie McMorgan",
  "Reuben Onyia",
  "Border Press",
  "Akkad Designs & Prints Production Ltd.",
  "Joe Waks Graphic Agency",
  "Nwashindi Jonathan Animam Earth Properties Limited",
  "Thankgod Photos",
  "Kenoz Graphics Studio, Enugu",
  "Andy Frankel",
  "Erhie Ohwovoriole",
  "Opuda Okumgba",
  "Amos Shola Akanbi",
  "Rotimi Osukoya",
  "Man Olivet",
  "Oghene - Efe And Associates",
  "Davidson Polygraphic Trade",
  "Jimson Advertising Productions, Ikeja",
  "Ajayi Kanayo Mokwenyei",
  "Okey Oyke Ikeme",
  "Martin Adefisayo Visuals",
  "Jimmi Soetan",
  "Creative Corner",
  "Jukat Productions",
  "Poatson Graphic Arts Trade Ltd.",
  "Matuba Art Services",
  "EddyTobi Die Making Enterprises",
  "Dr Orlando Owoh And His Kenneries Band",
  "R. A. Sule",
  "Lumex Industrial Press",
  "Raphael Nduka Monye",
  "Moses Egworuefa",
  "J.E Tully",
  "Ebekife Arts & Symbols",
  "Jocelyne Bellamy",
  "K. Bodun International Comoany Ltd.",
  "Fero Arts Nigeria",
  "Labchrome Ltd.",
  "Phonotar Graphic Arts Production",
  "Kenny Odoemena",
  "Best Print Advertising Limited",
  "Polygram",
  "Yonner / Lhôtellie",
  "O. Wiskee",
  "Nitin Patel",
  "Charles Imoloame",
  "MOIN (Ministry of Information Graphic Unit)",
  "Gabriel Ekofor",
  "A. O Obadoni",
  "Groof Gallery",
  "Adline Publicity",
  "Enaife Limited",
  "Innosco Press Limited",
  "Golden Okutu",
  "Icographics Industries",
  "Muyiwa Adetula",
  "Gboyega Femi",
  "Rasekola Campbell",
  "Sylv Agbubaeruleke",
  "Amoni Press Port Harcourt",
  "Austine Obadoni International Agencies",
  "Banji Asha",
  "Williams Ayode Balogun",
  "Israel Okanime",
  "Paul Okosi",
  "Grafikad",
  "Isoka Arts Onitsha, Israel Okanime",
  "Whiskee",
  "Chuks Alagwu",
  "C. Apajo",
  "Kenchid Design Group",
  "Muka-Adek Graphic",
  "Nduka Press",
  "Professional Art Studio Portharcourt",
  "Tonnie Advertising Production",
  "Poatson Maboju Ikeja",
  "Prince Eboagune Edem",
  "Yomi Otukoya",
  "Adeyinka Akinsaya",
  "Innosco Press (Nig.) Ltd.",
  "Bofa Printers",
  "Cecta (Nig) Ltd. Enugu",
  "Chinedu Anosike",
  "Sokas Graphic Printers People",
  "Chico Markson Insreal",
  "Ferum Printing Press",
  "Goof Gallery",
  "C. Akpai",
  "Nancy Jean Anderson",
  "Sylvester Degbor",
  "Ugographics",
  "Bassey E. Bassey",
  "Nico Okune Jr.",
  "Bayo Adetayo",
  "Ben Attudume",
  "Anthony Okonye",
  "Biodun Sowunmi",
  "CoMme Ça + Klaxon",
  "Ebtim Krafts Co",
  "Tonograph Advertising Co.",
  "Obvik Publicity",
  "SPP Photos",
  "Tabgraphics",
  "Silas Itagbo",
  "Liverpool Professional Arts",
  "Ehims Ind. Agencies",
  "Tabs Press",
  "Ogene Jay",
  "Rocon Lab Enugu",
  "Top Star Advertising (Nig) Ltd.",
  "Hector Collins Decker",
  "Nkechi Duru",
  "Yahya A. Quadri",
  "Wilfilms Limited",
  "Goddy Wichendu",
  "Mohammed Arahi",
  "Omo-Adeyinka Graphic Art",
  "Shunag Art Gallery",
  "Momo Photos",
  "Gilles Chagny",
  "Azad Ologbosere",
  "J.I Wariboko",
  "Johnson Art",
  "Grafik Arts International",
  "Idogho & Sons Graphic Services",
  "Ino/Enos O",
  "Garoda Inter Coy",
  "Laolu Aboderin",
  "Rhema Productions",
  "Rev. Kasi Azike",
  "Lithomark",
  "Mai Okeleke",
  "Sam A. Ofoegbu",
  "Patrick Griffith",
  "Rafael Angel Rosario",
  "Ogun State Printing Corporation",
  "Spider Arts Industries",
  "Gilkaf Graphics",
  "Daro Borire Press",
  "Gbolahan Faluade",
  "Adegboye (Keku)",
  "VynZuky",
  "Zee Creative",
  "Pablo Haz",
  "Amon Saba Saakana",
  "SIB-Tryck AB",
  "Acrobat Design Ltd.",
  "Maria Gilardin",
  "Sadiq Akinbani",
  "Akeep Alarape Adedeji Esq.",
  "Adrian Boot",
  "Felographic",
  "Ben O Edukore",
  "Austin Umurhurhu",
  "Basol International",
  "Emokpaire Printing Co.",
  "Majj Basol",
  "Ajjet (Nig) Enterprises",
  "Anele Ojini",
  "Kayseph Graphics",
  "Rosebird International Advertising Ltd.",
  "Babatunde Naphtali",
  "Biodun Odunsi Eshilokun",
  "Kolorgrafiks Studio Lagos",
  "Olu Abiodun",
  "Tunde Ogunnaike",
  "Seye Graphic Production",
  "Ankobra",
  "Cornistic Arinju",
  "Robert Mbamalu",
  "Murray Brenman",
  "Lynn Goldsmith",
  "Wale Johnson",
  "Gboloye Printing Works",
  "Lucky Lebarty",
  "Inter-Sonic Printers",
  "Ajao Graphics",
  "Clarendon Press",
  "Edward Cooper",
  "Atitoni Publications",
  "Alhaji L. A. Adesanya",
  "Sacrum Graphic Press, Aba",
  "A.V.P",
  "Lexi Ero",
  "Ewoscom",
  "MansMark Press",
  "Michael Okoro",
  "Montreuil Offset",
  "Charlie Anyakora",
  "Tunde Orimogunje",
  "Yomi Osunfisan",
  "Kes Akoren",
  "Gratex",
  "Tony Port",
  "Jacob Adeprint",
  "Peregrino Communications International Lagos",
  "Onacis Ltd.",
  "Ayo Manuel-Ajisebutu",
  "Felix O Samson",
  "Charles Ekanem Graphic Arts",
  "Okezie Press Otu-Ocha",
  "Kemah Agencies",
  "Chrismark Publicity",
  "Wados International",
  "3rd Eye Repro. Prints",
  "Gilkam Graphics",
  "Goodlife N. Daka",
  "S.S.B Enterprises",
  "Nadafographic",
  "Ossey Graphics",
  "Executive Complex Int.",
  "C.Ross",
  "Akannimaximum Publicity",
  "Sexton Rawlings",
  "J.M Igbini",
  "St. Christopher's Press Uyo",
  "Amabragha Nemimiegha",
  "Daylight Press",
  "Chuks Kodeci",
  "Artman Press",
  "Joe Ina",
  "Rick & Aza",
  "Pascaleen Art Company",
  "Mac Duama",
  "Godrick N. Osuji",
  "Goddy Akubue",
  "Vinc Iteh",
  "Colourwise Litho",
  "Adenuga Commercial Press",
  "Sendus Arts",
  "T. Howson-Wright",
  "Charles Anyakora",
  "Dave Beasley",
  "Cloud Nine Design",
  "Kolorgrafiks Studio",
  "Dom Onyegbula",
  "Ben Olu Cashman & Co Printers",
  "F.O Designs Co",
  "Taiwo Musa",
  "J.M. Igbini",
  "Handy Press Lagos",
  "Isaac Idirigbe",
  "Jean-Jacques Mandel",
  "Spectrum Associates",
  "Joe Hendrix",
  "Nadafo Commercial Enterprises",
  "Leader Records",
  "Emokpaire Printing Co.",
  "Samed Publicity Co",
  "Arthur Banmah",
  "Yellow Go-Rilla Production Ltd",
  "Kingston Publicity Co. Lagos",
  "Abiprint & Pak. Graphic House",
  "Modern Business Press",
  "J. Onifade",
  "Bidwell Nsofor",
  "IRI (Nig) Ltd",
  "Akin Bada",
  "Electromat Ltd",
  "Tholus",
  "S. O. Gentle",
  "S. Olu Gentle",
  "Pius Onyemekeihia",
  "Idoghoson Organisation",
  "Obiechina Ezeofor",
  "Suho Promotions Apapa Lagos",
  "Sunny N Nwakobi",
  "Deji Dosumu",
  "International Graphics Limited",
  "Leader Press",
  "Kengraph Agency",
  "Cographics, Aba",
  "Matinda Enterprises",
  "Femi Jolaoso",
  "Aik Idirigbe",
  "Okechukwu Nwosu",
  "Ogudele Comm Press",
  "Rowland Olu Printers & Co",
  "Ben Bayo Oyemade",
  "West Brothers Printers",
  "Smudge Ltd.",
  "O'Kemi A. Gabriel",
  "Chuddy Ogeah",
  "R. Abiodun Odunsi",
  "Augustine Umurhurhli",
  "J.B Onifade",
  "James Onifade",
  "O. Enoch",
  "Kwame O.R.O.",
  "Jinbrin Sons Photos",
  "Onibokun Creative Studios",
  "E.U Nzeka",
  "D. Locquet",
  "Omolou Pedro",
  "Ekwueme Nwankwo",
  "Moreman Graphics",
  "Peegee International",
  "David Seelig",
  "EMI",
  "Felix Mordi & Associates",
  "Ogundele Comm. Press",
  "Felix Obialor",
  "Samton enterprises",
  "Ernest Hastings",
  "Art Studio (4)",
  "Adamosa Printers",
  "Mike Byyvon",
  "Mbyyon",
  "Deposep Agencies",
  "Hadji L. Adesanya",
  "O. P. Onotare",
  "Tunde Akinleye",
  "Ade Onibokun",
  "Abiprint & Pak Ltd.",
  "Goddy Oku",
  "Sallu Deen",
  "Kobis Art & Press Agency",
  "Medi Commercial Agencies",
  "Tade Aborisade",
  "Karl Finn",
  "Edvinns",
  "Oleg Galay",
  "Spectrum Associates (2)",
  "Ajayi K. Mokwenyei",
  "Prince Abiodun Odunsi",
  "Marie O'Connell",
  "Senol Printing Ltd.",
  "Musiliu Peregrino Brimah",
  "Robert Stace",
  "Unity Advertising Ltd.",
  "Lati",
  "Mai Graphics of Nigeria",
  "Johny Graphic Art",
  "Alice Usuah",
  "Unicy",
  "Chito Prints",
  "Clout & Baker Ltd.",
  "Benjamin E Ekporo",
  "E Martins",
  "Alaba Fashua",
  "Pacific Printers Ltd.",
  "Sanya Ade Printers",
  "Descot Arts",
  "James Upton Ltd.",
  "Mariere Emeza",
  "Daniel Adjuya",
  "Babatunde Memorial Press",
  "Edward Harry",
  "E. Martins",
  "Bede Joe",
  "J. Oba. Ayeni",
  "Colour Flair Printers",
  "Reeves Gaba",
  "Johny-Co Enterprises",
  "All Saints Printing Works",
  "J.C.O Oniyamah",
  "FEO Publicity",
  "Austin Igbinoba Ugbogbo",
  "Promark Internnational Ltd.",
  "Stainless Photo Lab",
  "Boddus Graphic Printers",
  "Alex Olatunde",
  "Basil Okafor",
  "Tyrone Whyte",
  "Jill Mumford",
  "Robins E.U",
  "F. O. Samson",
  "Tony Esemokhai",
  "Mac-Duama",
  "C. Ross",
  "Mariere Designs Nig. Associates",
  "Harrigan Ebere",
  "Jay-U",
  "Brightson Printers",
  "GUSCO W. Germany",
  "MacNiel Press Ltd.",
  "Eli Peduzzi",
  "Okanlawon Banjoko",
  "Brian McIntyre",
  "Variety Industrial press Ltd.",
  "Benwillon Colour Grafiks",
  "John Igbini",
  "Sunny Olu Advertising Co.",
  "General Aracca Publicity Company",
  "Bonny Okafor",
  "Dosade Advert",
  "Nath Obiajulu",
  "Colossus Arts",
  "Keen' Tayo Adelakun",
  "Dom Nosike",
  "Femi Oyewale",
  "Annette Fisher",
  "John Mevayeero Coffee Igbini",
  "Yinka Aboderin",
  "Babalola Alves",
  "Akin Omolewa",
  "Dayo Alakija",
  "Donald F.A Biomoh",
  "F.O Samson",
  "Ghariokwu Lemmi",
  "Remi Olowookere",
  "Esto Printers",
  "Bob Ben",
  "A.R.E.A.C.E.M",
  "Paladen",
  "Akpulu Arts",
  "Joygraphic Art & Associate",
  "Tony Essien",
  "Deji Dosunmu",
  "CSS Press",
  "Femi Ewetade Promotions",
  "Gbengus Commercial Arts Trade",
  "Royal Publicity Company",
  "The Secret",
  "Ayo Ajayi",
  "Ebele And Chinye",
  "Delga Press Limited",
  "David Akinyemi",
  "David Johnson",
  "Hasida Arts",
  "Laurie Richards",
  "Makossa Art",
  "‘Jinduh",
  "Segun Sofowote",
  "Liberty Press Ltd.",
  "Blo",
  "Norman Batley Associates",
  "Aboola Folarin",
  "Promo Serve (Nig.) Ltd.",
  "Ossie Murray",
  "Usamachime Express Printing Press",
  "Terry Eze",
  "Femi Dairo",
  "Johnson Graphics",
  "Pius & Pius Graphic Artists",
  "Okerekeocha E",
  "Wasco Printers",
  "Uptown Printing Group",
  "DCNI",
  "Esko Inyan",
  "MPO",
  "Kayode Ademola",
  "Sunrem Publicity Company",
  "Ogunsina P. W.",
  "Pacific Printers Ltd., Yaba",
  "Moore & Matthes Ltd.",
  "Tony F. Goodchild",
  "Dawson Rossiter Ltd.",
  "GZ Digital Media",
  "West Brothers Printers Limited",
  "Ed Skyner",
  "St. James Press",
  "Segun Printing Press",
  "St James Press",
  "Babs O. Sapara",
  "Saxton Rawlings",
  "Emekpaire Printing Co.",
  "OtoFam Nig.",
  "Victor Okpekpe",
  "Process Design and Printing",
  "Lensall Graphics",
  "Diskmakers",
  "Carol Taback",
  "Goddy Akasike",
  "George and Sons Press",
  "Nweze Mbah",
  "CPI Moore Nig",
  "Hon-Bammy Rock enterprises",
  "Eddyco Die-Making Enterprises",
  "S.I.A.T.",
  "Larry O. Williams",
  "Joseph Wakama",
  "Times Press Ltd. Apapa Nigeria",
  "V.O Larry",
  "Samartco Designers",
  "Lekson Production",
  "Iyalomhe Industrial Press",
  "Garrod",
  "F'Olorunso Ayorinde",
  "Kay-Abbey Prints",
  "Okus Designs Co.",
  "Edmond bens",
  "Symac Design & Publicity",
  "Design Center, Lagos",
  "Nii Ayite Aryee",
  "Victor Kay Ndulue",
  "Chamson Publicity",
  "Darlington Oti",
  "Mike Ezeudo",
  "Sam Ogians",
  "Ezeco (Nig.) Printers",
  "Arts & Graft, Paris",
  "Siba Baga",
  "Spacomedia",
  "Cyril Ananuba Junior",
  "Adwal Innovatives",
  "John Green",
  "Average Communications",
  "Toni Art",
  "Isoka Arts",
  "Joe Sams",
  "X-Gamito Grafiks",
].sort((a, b) => {
  let fa = a.toLowerCase(),
    fb = b.toLowerCase();
  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
});

export const Years = Array.from({ length: 2024 - 1950 + 1 }, (_, i) =>
  (1950 + i).toString()
).sort((a, b) => Number(b) - Number(a));
